<script>
  import { onDestroy, onMount } from "svelte";
  import TaskExeCompList from "../task-exe-comp-list.svelte";
  import { taskExeBusinessObject } from "../task-exe-store";
  import apiService from "../../../services/api.service";

  /** @type {{id: string; columns: any[]}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let tplparents = [];
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let myVisibility;
  let currentParents = [];
  const pathToStore = tplparents.join("_") || "tpl-main";

  $: checkVisibility(visibilityFromParent, myVisibility);

  const taskExeBusinessObjectUnsubscribe = taskExeBusinessObject.subscribe(
    (bo) => {
      if (!bo) return;

      if (props.visibilityVar) {
        myVisibility = apiService.getNestedFromPath(
          bo[pathToStore],
          props.visibilityVar,
        );
        //console.log(
        //  "... container",
        //  props.id,
        //  myVisibility,
        //  pathToStore,
        //  bo[pathToStore],
        //);
      }
    },
  );

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
  });

  /**
   * @param {string} parentVisibility
   * @param {string} elVisibility
   */
  function checkVisibility(parentVisibility, elVisibility) {
    myVisibility = apiService.check.widgetVisibility(
      parentVisibility,
      elVisibility,
      props,
    );
  }
</script>

<!-- CONTAINER::: parent: {visibilityFromParent} / item: {myVisibility} / result: {props.visibility} -->
<!-- CONTAINER ({item.id})::: {myVisibility} / {props.visibility} -->
<!-- <br /> -->
<!-- <code>visibilityFromParent: {visibilityFromParent}</code> -->
<!-- CONTAINER TPLS: {JSON.stringify(tplparents)}<br /> -->
<!-- CONTAINER::: parent: {visibilityFromParent} / item: {myVisibility} / props: {JSON.stringify( -->
<!--   props, -->
<!-- )} -->
{#if myVisibility !== "hidden"}
  <div
    id={item.id}
    class={props.class || ""}
    class:container={!props.isFluid}
    class:container-fluid={props.isFluid}
    style={props.style}
  >
    {#each item.columns as child}
      <TaskExeCompList
        bind:item={child}
        bind:parent={currentParents}
        bind:tplparents
        bind:visibilityFromParent={myVisibility}
        bind:templateProps
      />
    {/each}
  </div>
{/if}
