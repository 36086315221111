<script>
  import { onDestroy, onMount } from "svelte";
  import { taskExeStore } from "../task-exe-store";
  import TaskExeCompList from "../task-exe-comp-list.svelte";
  import taskExeApi from "../task-exe.api";
  import { t } from "../../../services/i18n.service";

  /** @type {{id: string; columns: any[]}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string}[]} */
  export let parent = undefined;
  export let tplparents = [];
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  const STATUSES = {
    LOADING: "loading",
    TPL_FOUND: "tpl-found",
    TPL_NOT_FOUND: "tpl-not-found",
    TPL_RECURSIVE: "tpl-recursive",
  };
  let myVisibility;
  let dynamicBoStore = true;
  let currentParents = [...parent, props.id];
  let currentTplParents = [...tplparents, props.id];
  let status = STATUSES.LOADING;

  const pathParents = parent
    .filter((el) => el.type === "tpl-preview")
    .map((el) => el.id)
    .join("_");
  const pathOfId =
    pathParents.length > 0 ? pathParents + "_" + item.id : item.id;

  //const pathOfId = tplparents.join("_") || "tpl-main";

  $: updateVisibility(visibilityFromParent);

  onMount(() => {
    // TODO: add a constraint for TPL.RECURSIVE status
    if (!dynamicBoStore) {
      status = STATUSES.TPL_RECURSIVE;
      return;
    }

    const isTemplateActivated =
      taskExeApi.getLoadedTemplateActivation.check(pathOfId);
    if (!isTemplateActivated) {
      // NOTE: after loading the UI scan the definition.objects after templates and load them all
      // and then reference them here
      // load template and add it to ui definition:
      const uiTpl = taskExeApi.getLoadedTemplate(props.tplId);
      if (uiTpl) {
        // NOTE: check if the template objects is already in place in order to not update everything again
        // this update may be only because of a visibility update form hidden to visibile

        const dropzone = uiTpl.definition.dropzone.Dropzone;
        const objects = uiTpl.definition.objects;
        item.columns = [...dropzone];
        taskExeStore.update((store) => {
          store.definition.objects = {
            ...store.definition.objects,
            ...objects,
          };
          return store;
        });

        taskExeApi.getLoadedTemplateActivation.add(pathOfId);

        status = STATUSES.TPL_FOUND;
      } else {
        status = STATUSES.TPL_NOT_FOUND;
      }
    } else {
      status = STATUSES.TPL_FOUND;
    }
  });

  onDestroy(() => {});

  function updateVisibility(visibilityFromParent) {
    //console.log("... update visibility preview", visibilityFromParent);
    myVisibility = visibilityFromParent;
  }
</script>

<!-- TEMPLATE: {JSON.stringify(props.id)} / uiTplFound: {uiTplFound} -->
<!-- TEMPLATE: {JSON.stringify(props)} -->
<!-- TEMPLATE: {JSON.stringify(templateProps)} -->
<!-- TPL PARENTS: {currentTplParents} -->
<!-- <div class=""> -->
<!--   template: {JSON.stringify(props.name)} -->
<!-- </div> -->
<!-- TEMPLATE ({pathOfId}): <br>  -->
<!-- BO: {JSON.stringify($dynamicBoStore)} -->

<!-- TEMPLATE ({item.id}): {myVisibility} / {props.visibility} -->
<!-- TPL PREVIEW ({tplparents}): parent: {visibilityFromParent} / item: {myVisibility}<br /> -->
<!-- TPL BO: {JSON.stringify($taskExeBusinessObject)} -->
<!-- TPL PREVIEW ID: {props.id}<br /> -->
<!-- TPL PREVIEW PARENTS: {JSON.stringify(tplparents)}<br /> -->
{#if status === STATUSES.TPL_RECURSIVE}
  <div class="text-danger">{$t("msgs.recursiveTpl")} ({props.name})</div>
{:else if status === STATUSES.TPL_NOT_FOUND}
  <div class="text-danger">{$t("msgs.noTplFound")}</div>
{:else if status === STATUSES.LOADING}
  {$t("generic.loading")}
{:else if myVisibility !== "hidden"}
  <div id={item.id} class="tpl-preview">
    {#each item?.columns as child}
      <TaskExeCompList
        bind:item={child}
        bind:parent={currentParents}
        bind:tplparents={currentTplParents}
        bind:visibilityFromParent={myVisibility}
        templateProps={props}
      />
    {/each}
  </div>
{/if}
