<script>
  import { onDestroy, onMount } from "svelte";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import {
    taskExeBusinessObject,
    taskExeErrorStore,
    taskExeStore,
  } from "../task-exe-store";
  import taskExeApi from "../task-exe.api";
  import { isLoading } from "../../../stores";
  import apiService from "../../../services/api.service";
  import { currentLanguage } from "../../../services/i18n.service";
  import taskExeErrorService from "../task-exe-error.service";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let tplparents = [];
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let hasError = false;
  let showHelp = false;
  let value;
  let myVisibility;
  let widgetId = `${tplparents.join("_") || "tpl-main"}_${item.id}`;
  const pathToStore = tplparents.join("_") || "tpl-main";

  $: checkVisibility(visibilityFromParent, myVisibility);

  const taskExeBusinessObjectUnsubscribe = taskExeBusinessObject.subscribe(
    (bo) => {
      if (!bo) return;
      value = apiService.getNestedFromPath(bo[pathToStore], props.varName);

      if (props.visibilityVar) {
        myVisibility = apiService.getNestedFromPath(
          bo[pathToStore],
          props.visibilityVar,
        );
      }
    },
  );

  const taskExeErrorStoreUnsubscribe = taskExeErrorStore.subscribe(() => {
    const resultErrVars = taskExeErrorService.getErrorVars(pathToStore, props);
    if (resultErrVars?.boPath === pathToStore) {
      hasError = resultErrVars.hasError;
      showHelp = resultErrVars.showHelp;
    }
  });

  onMount(() => {
    valueChanged(true);
  });

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
    taskExeErrorStoreUnsubscribe();
  });

  /**
   * @param {boolean} [init]
   */
  function valueChanged(init) {
    const isInit = init === true;
    taskExeBusinessObject.ensureValue(
      pathToStore,
      props.varName,
      value,
      isInit,
    );

    if (isInit) return;

    // INFO: Keep this here to not show invalid state on first rendering
    taskExeErrorService.checkValidation(props, pathToStore);

    taskExeApi.submit({
      id: item.id,
      boPath: tplparents.join("_") || "tpl-main",
      tplId: templateProps?.tplId,
    });
  }

  /**
   * @param {string} parentVisibility
   * @param {string} elVisibility
   */
  function checkVisibility(parentVisibility, elVisibility) {
    myVisibility = apiService.check.widgetVisibility(
      parentVisibility,
      elVisibility,
      props,
    );
  }
</script>

<!-- RADIO::: parent: {visibilityFromParent} / item: {newVisibility} / result: {props.visibility} -->
<!-- {JSON.stringify($dynamicBoStore)} -->
<!-- TPL PARENTS {tplparents} -->
{#if myVisibility !== "hidden"}
  {#if !props.arrMultiple}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div id={item.id} class="mb-3">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="form-label form-check-label">
        <TaskExeTplI18nLabel bind:props />{props.required ? "*" : ""}
      </label>
      <br />
      <div class="form-check" class:form-check-inline={props.isInline}>
        <input
          class="form-check-input {props.class || ''}"
          class:is-invalid={hasError}
          style={props.style}
          type="radio"
          name={widgetId}
          id="tplrad_{widgetId}_1"
          value={true}
          bind:group={value}
          on:change={() => {
            valueChanged();
          }}
          disabled={myVisibility === "disabled" || $isLoading}
        />
        <label class="form-check-label" for="tplrad_{widgetId}_1">
          <TaskExeTplI18nLabel bind:props varProp="trueVal" />
        </label>
      </div>
      <div class="form-check" class:form-check-inline={props.isInline}>
        <input
          class="form-check-input {props.class || ''}"
          class:is-invalid={hasError}
          style={props.style}
          type="radio"
          name={widgetId}
          id="tplrad_{widgetId}_2"
          value={false}
          bind:group={value}
          on:change={() => {
            valueChanged();
          }}
          disabled={myVisibility === "disabled" || $isLoading}
        />
        <label class="form-check-label" for="tplrad_{widgetId}_2">
          <TaskExeTplI18nLabel bind:props varProp="falseVal" />
        </label>
      </div>

      {#if showHelp}
        <div id={props.id + "-HelpBlock"} class="invalid-feedback d-block">
          {props?.validationMsg_i18n?.[$currentLanguage]
            ? props.validationMsg_i18n[$currentLanguage]
            : props.validationMsg}
        </div>
      {/if}
    </div>
  {:else}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div id={item.id} class="mb-3">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="form-label form-check-label">
        <TaskExeTplI18nLabel bind:props />{props.required ? "*" : ""}
      </label>
      <br />
      {#each props?.arrMultiple?.split(",") as _checkField, index}
        <div class="form-check" class:form-check-inline={props.isInline}>
          <input
            class="form-check-input {props.class || ''}"
            class:is-invalid={hasError}
            style={props.style}
            type="radio"
            name={widgetId}
            id="tplrad_{widgetId}_{index}"
            value={_checkField}
            bind:group={value}
            on:change={() => {
              valueChanged();
            }}
            disabled={myVisibility === "disabled" || $isLoading}
          />
          <label class="form-check-label" for="tplrad_{widgetId}_{index}">
            <TaskExeTplI18nLabel bind:props varProp="arrMultiple" {index} />
          </label>
        </div>
      {/each}

      {#if showHelp}
        <div id={props.id + "-HelpBlock"} class="invalid-feedback d-block">
          {props?.validationMsg_i18n?.[$currentLanguage]
            ? props.validationMsg_i18n[$currentLanguage]
            : props.validationMsg}
        </div>
      {/if}
    </div>
  {/if}
{/if}
