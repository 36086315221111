<script>
  import { ArrowsMove, DashSquare, X } from "svelte-bootstrap-icons";
  import { t } from "../../services/i18n.service";
  import { onDestroy, onMount } from "svelte";
  import { tooltip } from "../../services/tooltip.service";
  import { platformInfoStore } from "../../stores";
  import AiComponentDocs from "../ai/ai-component-docs.svelte";

  let showModal = false;
  const icoHeight = 24;
  let modalComponent;
  let aiOpt;
  let minimized = false;
  let isResizing = false;
  let modalWidth;
  let modalHeight;
  let isMoving = false;
  let moved = false;

  /**
   * @param {{ what: string; }} setts
   */
  export function init(setts) {
    aiOpt = setts.what;
    showModal = !showModal;
    minimized = false;
    toggleModal();
  }

  onMount(() => {});

  onDestroy(() => {});

  function handleMouseDownDrag(event) {
    isMoving = true;

    const startX = event.offsetX;
    const startY = event.offsetY;

    function handleMouseMoveDrag(e) {
      if (isMoving) {
        moved = true;
        modalComponent.style.bottom = "auto";
        modalComponent.style.top = `${e.clientY - startY - 18}px`;
        modalComponent.style.left = `${e.clientX - startX - 18}px`;
        modalComponent.style.cursor = "move";
        modalComponent.style.userSelect = "none";
      }
    }

    function handleMouseUpDrag() {
      isMoving = false;
      modalComponent.style.cursor = "inherit";
      modalComponent.style.userSelect = "inherit";
      window.removeEventListener("mousemove", handleMouseMoveDrag);
      window.removeEventListener("mouseup", handleMouseUpDrag);
    }

    window.addEventListener("mousemove", handleMouseMoveDrag);
    window.addEventListener("mouseup", handleMouseUpDrag);
  }

  function handleMouseDown(event) {
    isResizing = true;

    // Store the initial position and size
    const startX = event.clientX;
    const startY = event.clientY;
    //const box = event.target.getBoundingClientRect();
    const startWidth = modalWidth;
    const startHeight = modalHeight;

    function handleMouseMove(e) {
      if (isResizing) {
        // Calculate new width and height based on mouse movement
        modalWidth = startWidth + (e.clientX - startX);
        modalHeight = startHeight + (e.clientY - startY);
      }
    }

    function handleMouseUp() {
      isResizing = false;
      // Remove the event listeners
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }

    // Add event listeners to the window to track movement
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  }

  function calculateModalBottom(node) {
    node.style.bottom = "inherit";
    const box = node.getBoundingClientRect();
    const height = box.height;
    const top = box.top;
    let bottom = window.innerHeight - top - height;
    modalWidth = box.width;
    if (modalWidth <= 400) modalWidth = 400;
    modalHeight = box.height;
    //console.log(".. .starts", { box, height, top, bottom });
    if (bottom <= 30 || bottom > 600) {
      if (!moved) {
        node.style.top = `${top + bottom - 30}px`;
        node.style.left = `${window.innerWidth - modalWidth - 30}px`;
      }
    }
  }

  function refresh() {
    scrollToBottom();
  }

  function toggleModal() {
    if (showModal) {
      scrollToBottom();
    } else {
      moved = false;
    }
  }

  function scrollToBottom() {
    setTimeout(() => {
      if (modalComponent) {
        //calculateModalBottom(modalComponent);
      }
    }, 10);
  }
</script>

{#if showModal}
  <!-- style="width: {modalWidth}px; height: {modalHeight}px;" -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div
    class="rounded-3 border border-primary p-3 modal-view bg-body"
    style="width: {modalWidth}px;"
    bind:this={modalComponent}
  >
    <h5 class="d-flex">
      <div
        class="me-auto"
        style="cursor: move;"
        on:mousedown|stopPropagation|preventDefault={handleMouseDownDrag}
      >
        <span>
          <ArrowsMove width={icoHeight} height={icoHeight} />
        </span>
        <span class="text-primary text-uppercase">
          {#if $platformInfoStore?._n}
            {$platformInfoStore._n.f}
          {:else}
            Tasky Cloud
          {/if}
        </span>
        AI Chat <i>(beta)</i>
      </div>
      <button
        class="btn btn-sm btn-outline-secondary p-1 m-0 border-0"
        use:tooltip={$t("generic.minimize")}
        on:click={() => {
          minimized = !minimized;
          moved = true;
          calculateModalBottom(modalComponent);
        }}
      >
        <DashSquare width={icoHeight - 2} height={icoHeight - 2} />
      </button>
      <button
        class="btn btn-sm btn-outline-secondary p-1 m-0 border-0"
        use:tooltip={$t("generic.close")}
        on:click={() => {
          showModal = false;
          toggleModal();
        }}
      >
        <X width={icoHeight - 2} height={icoHeight - 2} />
      </button>
    </h5>

    <div class:visually-hidden={minimized}>
      {#if aiOpt === "ai-docs"}
        <AiComponentDocs></AiComponentDocs>
      {/if}
    </div>

    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div
      class="resizer border border-5 border-primary border-top-0 border-start-0"
      on:mousedown|stopPropagation|preventDefault={handleMouseDown}
    ></div>
  </div>
{/if}

<style>
  .modal-view {
    /* height: 400px; */
    width: 400px;
    /*max-width: 50%;*/
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 99999;
    -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.59);
    -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.59);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.59);
  }

  .resizer {
    position: absolute;
    width: 25px;
    height: 25px;
    right: 0;
    bottom: 0;
    cursor: se-resize;
    background-color: transparent;
  }
</style>
