<script>
  import { onDestroy, onMount } from "svelte";
  import { taskExeBusinessObject, taskExeStore } from "../task-exe-store";
  import { currentLanguage } from "../../../services/i18n.service";
  import jq from "jquery";
  import taskExeApi from "../task-exe.api";
  import _ from "underscore";
  import toasterService from "../../../services/toaster.service";
  import systemService from "./../../../services/system.service";
  import { get } from "svelte/store";
  import { isLoading } from "../../../stores";
  import dayjs from "dayjs";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string}[]} */
  export let parent = undefined;
  export let tplparents = [];
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;
  export let visibilityFromParent = "editable";

  let updateFromStore = true;
  let boundEventListenerLocalChangedInJS;
  let local = {};
  let i18n_currentLanguage = "en";
  let selfElement;
  let angularApp;

  const pathToStore = tplparents.join("_") || "tpl-main";
  const elementId = pathToStore ? pathToStore + "_" + item.id : item.id;

  const system = systemService.getSystem();

  const currentLanguageUnsubscribe = currentLanguage.subscribe((res) => {
    i18n_currentLanguage = res;
    const localChangedEvent = new CustomEvent(`i18nChanged_${elementId}`, {
      detail: res,
    });
    window.dispatchEvent(localChangedEvent);
  });

  const taskExeBusinessObjectUnsubscribe = taskExeBusinessObject.subscribe(
    (boInit) => {
      if (!boInit) return;
      const bo = boInit[pathToStore];
      if (!bo) return;
      if (props.useAngularJS) {
        if (!updateFromStore) return;
        Object.keys(bo).forEach((key) => {
          local[key] = bo[key];
        });
        const localChangedEvent = new CustomEvent(`localChanged_${elementId}`, {
          detail: local,
        });
        window.dispatchEvent(localChangedEvent);
      } else {
        Object.keys(bo).forEach((key) => {
          local[key] = bo[key];
        });
        const localChangedEvent = new CustomEvent(`localChanged_${elementId}`, {
          detail: local,
        });
        window.dispatchEvent(localChangedEvent);
      }
    },
  );

  onDestroy(() => {
    // window.removeEventListener(`localChanged_${item.id}`);
    taskExeBusinessObjectUnsubscribe();
    currentLanguageUnsubscribe();
    destroyAngularApp();
    window.removeEventListener(
      `localChanged_${elementId}`,
      boundEventListenerLocalChangedInJS,
    );
    window.removeEventListener(
      `i18nChanged_${elementId}`,
      boundEventListenerLocalChangedInJS,
    );
  });

  function destroyAngularApp() {
    //console.log("... destroying", "#my-angular-app-" + elementId);
    if (angularApp) {
      const myAngularElement = jq(selfElement).find(
        "#my-angular-app-" + elementId,
      )[0];
      const scope = angular.element(myAngularElement).scope();
      //console.log("... scope", scope);
      if (scope) scope.$destroy();
      // const injector = angular.element(myAngularElement).injector();
      // injector.get("$rootScope").$destroy();
      // angular.element(myAngularElement).remove();
      jq(selfElement)
        .find("#my-angular-app-" + elementId)
        .remove();
      angularApp = void 0;
      //console.log(
      //  "... ngapps remove",
      //  "#my-angular-app-" + elementId,
      //  window.ngapps,
      //  myAngularElement,
      //  scope,
      //  angular.element(myAngularElement).data(),
      //  jq(selfElement),
      //  angularApp,
      //);
    }
  }

  onMount(() => {
    // manual bootstrap process
    // @ts-ignore
    const app = window.angular.module(`myApp_${elementId}`, []);
    app.filter("unsafe", [
      "$sce",
      function ($sce) {
        return $sce.trustAsHtml;
      },
    ]);
    app.factory("$exceptionHandler", function () {
      return function (error, cause) {
        toasterService.error({
          msg: error + "\r\n Please check Browser Console",
          timeout: 0,
        });
        console.error(
          `Check the following error in ${props.type} widget (id: ${props.id}), with content: \n${props.script}`,
        );
        console.error(error, cause);
      };
    });
    app.controller("MainController", [
      "$scope",
      "$timeout",
      function ($scope, $timeout) {
        $scope.local = local;
        $scope.system = system;
        $scope.i18n = { locale: i18n_currentLanguage };
        $scope.jQuery = jq;
        $scope._ = _;
        $scope.dayjs = dayjs;
        $scope.widgetElement = selfElement;
        $scope.$timeout = $timeout;
        $scope.subscribeLocal = (bo) => {};
        $scope.isLoading = () => {
          return (function () {
            return get(isLoading);
          })();
        };
        // INFO: user should also put the template id if the button is from another template or 'genera';
        // ex: tpl-preview_EhlqPx-hIO
        $scope.submitByWidgetId = (id, templateId) => {
          return (function (id, templateId) {
            if (!id) {
              alert(
                "Err: No widget ID received in this.submitById() function. Check your HTML/JS script",
              );
              return;
            }
            // check for templateId:
            let tplId;
            if (templateId) {
              if (templateId === "general") {
                tplId = undefined;
              } else {
                const templateWidget =
                  get(taskExeStore)?.definition?.objects?.[templateId];
                if (!templateWidget?.tplId) {
                  alert(
                    `Err: Widget HTML/JS (${props.id}) is not configured correctly. A .submitByWidgetId method was set with btnId ${id} but no widget/template id found`,
                  );
                  return;
                }
                tplId = templateWidget.tplId;
              }
            } else {
              tplId = templateProps?.tplId;
            }
            taskExeApi.submit({
              id,
              boPath: pathToStore,
              tplId: tplId,
            });
          })(id, templateId);
        };
        // $timeout(() => {
        //   const { local, i18n, jQuery, _, submitByWidgetId } = $scope;
        // });

        // listen for the 'localChanged' event
        function EventListenerLocalChanged(/** @type {{detail: any}} */ event) {
          $timeout(() => {
            $scope.$apply(function () {
              $scope.local = event.detail;
              if (window.angular.isFunction($scope.subscribeLocal))
                $scope.subscribeLocal($scope.local);
            });
          });
        }

        // listen for the 'i18nChanged' event
        function EventListenerI18nChanged(/** @type {{detail: any}} */ event) {
          $timeout(() => {
            $scope.$apply(function () {
              $scope.i18n.locale = event.detail;
            });
          });
        }

        window.addEventListener(
          `localChanged_${elementId}`,
          EventListenerLocalChanged,
        );
        window.addEventListener(
          `i18nChanged_${elementId}`,
          EventListenerI18nChanged,
        );

        // $scope.$on('$destroy', function () {
        //   console.log('... destroy scope of', "#my-angular-app-" + elementId, selfElement)
        //   $scope.subscribeLocal = void(0);
        //   $scope.isLoading = void(0);
        //   $scope.submitByWidgetId = void(0);
        // })
        $scope.$on("$destroy", () => {
          //console.log(
          //  "... destroy scope of",
          //  "#my-angular-app-" + elementId,
          //  selfElement,
          //);
          $scope.subscribeLocal = void 0;
          $scope.isLoading = void 0;
          $scope.submitByWidgetId = void 0;
          window.removeEventListener(
            `localChanged_${elementId}`,
            EventListenerLocalChanged,
          );
          window.removeEventListener(
            `i18nChanged_${elementId}`,
            EventListenerI18nChanged,
          );
        });

        $scope.$watch(
          "local",
          function (/** @type {any} */ newVal) {
            const event = new CustomEvent(`angularLocalChanged_${elementId}`, {
              detail: newVal,
            });
            window.dispatchEvent(event);
          },
          true,
        );

        new Function(props.script).bind($scope)();
      },
    ]);
    const myAngularElement = jq(selfElement).find(
      "#my-angular-app-" + elementId,
    )[0];
    window.angular.element(document).ready(function () {
      angularApp = window.angular.bootstrap(myAngularElement, [
        `myApp_${elementId}`,
      ]);
      if (!window.ngapps) window.ngapps = {};
      window.ngapps["#my-angular-app-" + elementId] = angularApp;
      //console.log("... ngapps", window.ngapps);
    });
  });

  function updateBo() {
    taskExeBusinessObject.update((boInit) => {
      const bo = boInit[pathToStore];
      Object.keys(local).forEach((key) => {
        bo[key] = local[key];
      });
      return boInit;
    });
  }

  // Listen for changes from the AngularJS app
  function EventListenerLocalChangedAngular(
    /** @type {{detail: any;}}*/ event,
  ) {
    //console.log("... event from local angular", event.detail, pathToStore);
    local = event.detail;
    updateFromStore = false;
    taskExeBusinessObject.updateBO(pathToStore, local);
    // dynamicBoStore.update((bo) => {
    //   if (!bo) bo = {};
    //   Object.keys(local).forEach((key) => {
    //     bo[key] = local[key];
    //   });
    //   return bo;
    // });
    updateFromStore = true;
  }
  window.addEventListener(
    `angularLocalChanged_${elementId}`,
    EventListenerLocalChangedAngular,
  );
</script>

<!-- {JSON.stringify(templateProps)} -->
<!-- {parentsTplPath} {JSON.stringify($dynamicBoStore)} -->
<!-- {elementId} / {parentsTplPath} -->
<!-- HTML NG: {JSON.stringify(tplparents)} -->
<div id={elementId} bind:this={selfElement} class="mb-3">
  <div id="my-angular-app-{elementId}" ng-controller="MainController">
    {@html props.html}
  </div>
</div>
