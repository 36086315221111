<script>
  import { onDestroy, onMount } from "svelte";
  import dmsGcrService from "../components/dms/dms-gcr.service";
  import FieldAce from "../components/utils/field-ace.svelte";
  import FieldJsonTree from "../components/utils/field-json-tree.svelte";
  import UtilCardTitle from "../components/utils/util-card-title.svelte";
  import UtilModalConfimation from "../components/utils/util-modal-confimation.svelte";
  import UtilModal from "../components/utils/util-modal.svelte";
  import UtilPagination from "../components/utils/util-pagination.svelte";
  import UtilSearch from "../components/utils/util-search.svelte";
  import UtilTable from "../components/utils/util-table.svelte";
  import aclService from "../services/acl.service";
  import constsService from "../services/consts.service";
  import { t, tNow } from "../services/i18n.service";
  import toasterService from "../services/toaster.service";
  import { platformInfoStore, userStore } from "../stores";
  import DocVersions from "../components/dms/doc-versions.svelte";

  let platformInfo;
  let itemsNo = 0;
  let items = [];
  let modalConfirmDelete;
  let modalShow = false;
  let modalContentMode = "view"; // 'edit'
  let modalButtons = [
    {
      label: $t("generic.close"),
      action: "close",
      class: "btn-outline-secondary",
    },
    {
      label: $t("generic.edit"),
      action: "edit",
      class: "btn-primary",
    },
    // {
    //   label: $t("generic.view"),
    //   action: "view",
    //   class: "btn-primary",
    // },
    {
      label: $t("generic.save"),
      action: "save",
      class: "btn-primary",
    },
  ];
  let modalButtonsBkup = JSON.stringify(modalButtons);
  let currentItem;
  let currentItem_bkup;
  let query = {
    sort: "-created_at",
    limit: constsService.QUERY_PAGE_LIMIT,
    skip: 0,
    dms: true,
  };
  let can = {};
  let isLoading = false;
  let modalDocShow = false;
  let modalDocUrl;

  const userStoreUnsubscribe = userStore.subscribe(() => {
    can.view = aclService.can("read", "Document");
    can.edit = aclService.can("update", "Document");
    can.delete = aclService.can("delete", "Document");
  });

  // getPlatformInfo
  const platformInfoStoreUnsubscribe = platformInfoStore.subscribe((res) => {
    platformInfo = res;
  });

  onMount(() => {
    search();
  });

  onDestroy(() => {
    platformInfoStoreUnsubscribe();
    userStoreUnsubscribe();
  });

  function search() {
    countItems();
    getItems();
  }

  function getItems() {
    isLoading = true;
    dmsGcrService
      .get(query)
      .then((res) => {
        items = res.data;
        items.forEach(
          (
            /** @type {{ userInfo: string; userId: { firstName: string; lastName: string; }; }} */ el,
          ) => {
            el.userInfo = el.userId.firstName + " " + el.userId.lastName;
          },
        );
        isLoading = false;
      })
      .catch(() => {
        isLoading = false;
      });
  }

  function countItems() {
    dmsGcrService.count(query).then((res) => (itemsNo = res.data[0]));
  }

  /**
   * @param {{ bucket: string; cloudStorageObject: string; }} doc
   */
  function viewItem(doc) {
    const dmsrepo = platformInfo.dmsrepo;
    if (dmsrepo === "tasky") {
      const url =
        "https://storage.googleapis.com/" +
        doc.bucket +
        "/" +
        doc.cloudStorageObject;
      modalDocUrl = url;
      modalDocShow = true;
      // window.open(url, "_blank");
    } else if (dmsrepo === "filesystem") {
      const url =
        constsService.SERVER_PATH + "/dmsrepo/" + doc.cloudStorageObject;
      modalDocUrl = url;
      modalDocShow = true;
      // window.open(url, "_blank");
    } else {
      toasterService.error({ msg: tNow("dms.msgWhichRepo") });
    }
  }

  /**
   * @param {any} item
   */
  function editItem(item) {
    currentItem_bkup = JSON.stringify(item);
    currentItem = JSON.parse(JSON.stringify(item));
    modalButtons = JSON.parse(modalButtonsBkup);
    modalButtons.splice(2, 1);
    modalShow = true;
  }

  /**
   * @param {string} action
   */
  function modalClose(action) {
    if ("close" === action) {
      modalContentMode = "view";
      currentItem = undefined;
      modalShow = false;
      return;
    }
    // if ("view" === action) {
    //   currentItem = JSON.parse(currentItem_bkup);
    //   modalContentMode = "view";
    // }
    if ("edit" === action) {
      modalButtons = JSON.parse(modalButtonsBkup);
      modalButtons.splice(1, 1);
      currentItem = JSON.stringify(
        JSON.parse(currentItem_bkup).metadata,
        null,
        4,
      );
      modalContentMode = "edit";
    }
    if ("save" === action) {
      const docObject = JSON.parse(currentItem_bkup);
      docObject.metadata = JSON.parse(currentItem);
      // console.log('... save doc', docObject, currentItem)
      dmsGcrService
        .save(docObject)
        .then(() => {
          getItems();
          currentItem = null;
          currentItem_bkup = null;
          modalShow = false;
          modalContentMode = "view";
          toasterService.success({ msg: tNow("generic.saved") });
        })
        .catch((err) => {
          console.error(".... err", err);
        });
    }
  }

  function deleteItem() {
    dmsGcrService
      .remove(currentItem._id)
      .then(() => {
        toasterService.success({ msg: tNow("generic.deleted") });
        search();
      })
      .catch((err) => console.error);
  }

  function modalDocClose(action) {
    if ("close" === action) {
      modalDocShow = false;
      modalDocUrl = null;
    }
  }

  function parseVersionPath(doc) {
    let result = "-";
    if (doc?.versInfo?.docId) {
      result = doc.versInfo.vers.toFixed(1) || "1.0";
    }
    return result;
  }
</script>

<div class="card">
  <div class="card-header bg-primary">
    <UtilCardTitle
      title={$t("dms.title")}
      desc={$t("home.admin.docRepo.desc") +
        " | " +
        $t("generic.total") +
        ": " +
        (itemsNo || 0)}
    />
  </div>
  <div class="card-body">
    <UtilSearch
      bind:query
      queryFn={search}
      fields={[
        {
          lbl: $t("generic.email"),
          ph: null,
          path: "user",
          primary: true,
          secondary: true,
        },
        {
          lbl: $t("generic.name"),
          ph: null,
          path: "file",
          primary: true,
          secondary: true,
        },
      ]}
    />

    <UtilTable
      data={items}
      bind:query
      queryFn={search}
      bind:dataLoading={isLoading}
      fns={{
        parseVersionPath: parseVersionPath,
      }}
      setts={{
        conf: {
          header: {},
          items: {
            view: { show: true },
            edit: { show: true },
            delete: { show: true },
          },
        },
        cols: [
          {
            lbl: $t("generic.name"),
            path: "fileName",
            type: "string",
            sort: true,
          },
          {
            lbl: $t("generic.user"),
            path: "userInfo",
            type: "string",
            sort: true,
            tl: { path: "user" },
          },
          {
            lbl: $t("generic.created"),
            path: "created_at",
            type: "date",
            sort: true,
          },
          {
            lbl: $t("generic.version"),
            path: { fn: "parseVersionPath" },
            type: "string",
            sort: false,
            slot: {
              comp: DocVersions,
              location: "after",
              props: { openDocument: viewItem },
            },
          },
        ],
      }}
      on:view={(ev) => viewItem(ev.detail)}
      on:edit={(ev) => editItem(ev.detail)}
      on:delete={(ev) => {
        currentItem = ev.detail;
        modalConfirmDelete.show({
          modalTitle: $t("dms.diaRemDocTitle"),
          modalBody: $t("dms.diaRemDocMsg"),
        });
      }}
    />

    <UtilPagination
      bind:query
      bind:totalData={itemsNo}
      on:callData={getItems}
    />
  </div>
</div>

<UtilModal
  title={$t("diaDmsBo.title")}
  open={modalShow}
  autoClose={false}
  onClosed={modalClose}
  bind:modalButtons
  size="lg"
>
  <div>
    {#if modalContentMode === "view"}
      <FieldJsonTree bind:value={currentItem} />
    {/if}
    {#if modalContentMode === "edit"}
      <FieldAce bind:value={currentItem} mode="json" />
    {/if}
  </div>
</UtilModal>

<UtilModalConfimation
  bind:this={modalConfirmDelete}
  size="md"
  headerBg="bg-danger text-white"
  modalButtons={[
    { label: $t("generic.close"), action: "close", class: "btn-secondary" },
    {
      label: $t("generic.delete"),
      action: "delete",
      class: "btn-outline-danger",
    },
  ]}
  on:delete={deleteItem}
/>

<UtilModal
  open={modalDocShow}
  size="fullscreen"
  onClosed={modalDocClose}
  autoClose={false}
  closeOnEscape={true}
  headerHide={true}
  title={$t("generic.document")}
>
  <!-- {modalDocUrl} -->
  <iframe
    title={$t("generic.document")}
    height="97%"
    width="100%"
    src={modalDocUrl}
  ></iframe>
</UtilModal>
