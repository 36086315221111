<script>
  import apiService from "../../../services/api.service";
  import { taskExeBusinessObject } from "../../task_bkup/task-exe-store";
  import TaskExeCompList from "../task-exe-comp-list.svelte";
  import { onDestroy } from "svelte";

  /** @type {{id: string; columns: any[];}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let tplparents = [];
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let hasError = false;
  let currentParents = [];
  let myVisibility;
  const pathToStore = tplparents.join("_") || "tpl-main";

  $: checkVisibility(visibilityFromParent, myVisibility);

  const taskExeBusinessObjectUnsubscribe = taskExeBusinessObject.subscribe(
    (bo) => {
      if (!bo) return;
      if (props.visibilityVar) {
        myVisibility = apiService.getNestedFromPath(
          bo[pathToStore],
          props.visibilityVar,
        );
      }
    },
  );

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
  });

  /**
   * @param {string} parentVisibility
   * @param {string} elVisibility
   */
  function checkVisibility(parentVisibility, elVisibility) {
    myVisibility = apiService.check.widgetVisibility(
      parentVisibility,
      elVisibility,
      props,
    );
  }
</script>

<!-- ROW ({item.id}): {myVisibility} / {props.visibility} -->
<!-- ROW: parent: {visibilityFromParent} / item: {myVisibility} -->
{#if myVisibility !== "hidden"}
  <div
    id={item.id}
    class="row {props.class ? props.class : ''}"
    style={props.style}
  >
    {#each item?.columns as child}
      <TaskExeCompList
        bind:item={child}
        bind:parent={currentParents}
        bind:tplparents
        bind:visibilityFromParent={myVisibility}
        bind:templateProps
      />
    {/each}
  </div>
{/if}
