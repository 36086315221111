<script>
  import { onDestroy } from "svelte";
  import { isLoading } from "../../../stores";
  import taskExeApi from "../task-exe.api";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import { taskExeBusinessObject } from "../task-exe-store";
  import apiService from "../../../services/api.service";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let tplparents = [];
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let myVisibility;
  const pathToStore = tplparents.join("_") || "tpl-main";

  $: checkVisibility(visibilityFromParent, myVisibility);

  const taskExeBusinessObjectUnsubscribe = taskExeBusinessObject.subscribe(
    (bo) => {
      if (!bo) return;
      if (props.visibilityVar) {
        myVisibility = apiService.getNestedFromPath(
          bo[pathToStore],
          props.visibilityVar,
        );
      }
    },
  );

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
  });

  /**
   * @param {string} parentVisibility
   * @param {string} elVisibility
   */
  function checkVisibility(parentVisibility, elVisibility) {
    myVisibility = apiService.check.widgetVisibility(
      parentVisibility,
      elVisibility,
      props,
    );
  }

  function buttonClicked() {
    taskExeApi.submit({
      id: item.id,
      boPath: pathToStore,
      tplId: templateProps?.tplId,
    });
  }
</script>

<!-- {JSON.stringify(templateProps)} -->
<!-- {JSON.stringify(parent)} -->
<!-- :::parent {parent?.id} / {parent?.visibility} -->

<!-- {templateProps?.id} / {item.id} / {myVisibility} -->
{#if myVisibility !== "hidden"}
  {#if props.classBtnFullWidth}
    <div
      class="mb-3 {props.classBtnFullWidth ? 'd-grid gap-2' : ''}"
      id={item.id}
    >
      <button
        class="btn {props?.btnClass
          ? 'btn-' + (props.btnClassOutline ? 'outline-' : '') + props.btnClass
          : 'btn-outline-primary'} {props.class ? props.class : ''}"
        style={props.style}
        disabled={myVisibility === "disabled" || $isLoading}
        on:click={() => {
          buttonClicked();
        }}
      >
        <TaskExeTplI18nLabel bind:item bind:props />
      </button>
    </div>
  {:else}
    <button
      id={item.id}
      class="btn mb-3 me-3 {props?.btnClass
        ? 'btn-' + (props.btnClassOutline ? 'outline-' : '') + props.btnClass
        : 'btn-outline-primary'} {props.class ? props.class : ''}"
      style={props.style}
      disabled={myVisibility === "disabled" || $isLoading}
      on:click={() => {
        buttonClicked();
      }}
    >
      <TaskExeTplI18nLabel bind:item bind:props />
    </button>
  {/if}
{/if}
