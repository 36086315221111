<script>
	import { Eye } from "svelte-bootstrap-icons";
	import UtilModal from "../utils/util-modal.svelte";
	import { onMount } from "svelte";
	import { t } from "../../services/i18n.service";
	import FieldDateFormated from "../utils/field-date-formated.svelte";
	import taskExeDocumentService from "../task/task-exe-document.service";

	/**
	 * @type {{versInfo: {docId: string;}}}
	 */
	export let item = undefined;
	export let props = {};

	let modalDocVersionShow = false;
	let newVersionValidation;
	let newVersionInfo;
	let currentDoc;
	let showNewVersion = false;
	let currentVersions = [];

	onMount(() => {
		docVersionReset();
	});

	/**
	 * @param {any} doc
	 */
	function showDocVersions(doc) {
		currentDoc = doc;
		getDocumentVersions();
		modalDocVersionShow = true;
	}

	function getDocumentVersions() {
		const docId = currentDoc?.versInfo?.docId;
		currentVersions = [];
		if (!docId) return;
		taskExeDocumentService
			.getVersions(docId)
			.catch((err) => {
				console.error(err);
			})
			.then((res) => {
				currentVersions = res.data || [];
			});
	}

	/**
	 * @param {string} action
	 */
	function modalDocVersionClose(action) {
		if ("close" === action) {
			docVersionReset();
		}
	}

	function docVersionReset() {
		newVersionInfo = { version: null, comment: null, type: "major" };
		newVersionValidation = { version: false };
		currentDoc = null;
		modalDocVersionShow = false;
		showNewVersion = false;
	}

	/**
	 * @param {any} doc
	 */
	function openDocument(doc) {
		props.openDocument(doc);
	}
</script>

{#if item?.versInfo?.docId}
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<!-- svelte-ignore a11y-no-static-element-interactions -->
	<span
		class="selectable-item ms-2"
		style="position: relative; top: -2px;"
		on:click={() => {
			showDocVersions(item);
		}}
	>
		<Eye></Eye>
	</span>
{/if}

<UtilModal
	open={modalDocVersionShow}
	size="lg"
	onClosed={modalDocVersionClose}
	autoClose={false}
	closeOnEscape={true}
	headerHide={true}
	title={$t("generic.versioning")}
>
	<div>
		{$t("generic.versions")}
	</div>
	<ul class="list-group view-height-vers">
		{#each currentVersions as doc}
			<li class="list-group-item">
				<h4
					class="list-group-item-heading d-flex"
					style="line-height: 10px; font-size: 1em; "
				>
					<!-- svelte-ignore a11y-click-events-have-key-events -->
					<!-- svelte-ignore a11y-no-static-element-interactions -->
					<span
						class="selectable-item"
						style="position: relative; top: 2px;"
						on:click={() => {
							openDocument(doc);
						}}
					>
						{doc.fileName}
					</span>
					<span class="ms-auto fw-bold">
						( v. {doc.versInfo?.vers?.toFixed(1) || "1.0"}
						{#if currentDoc?.versInfo?.vers === doc.versInfo.vers}
							<span class="text-muted">#{$t("generic.versionCurrent")}</span>
						{/if}
						)
					</span>
				</h4>
				<p class="list-group-item-text" style="line-height: 1.2; margin: 0px;">
					<span style="display: inline-block; font-size: 0.8em;">
						<span class="mcm-label">{$t("generic.created")}:</span>
						<span class="mcm-value">
							<FieldDateFormated date={doc.created_at} />
						</span>
					</span>
					<span style="display: inline-block; font-size: 0.8em;">
						<span class="mcm-label">{$t("generic.updated")}:</span>
						<span class="mcm-value">
							<FieldDateFormated date={doc.updated_at} />
						</span>
					</span>
					<span style="display: inline-block; font-size: 0.8em;">
						<span class="mcm-label">{$t("generic.user")}:</span>
						<span class="mcm-value"
							>{doc?.userId?.lastName} {doc?.userId?.firstName}</span
						>
					</span>
				</p>
				<p class="list-group-item-text" style="line-height: 1.2; margin: 0px;">
					<span class="mcm-label">{$t("generic.comments")}:</span>
					<span class="mcm-value">{doc?.versInfo?.comm || "-"}</span>
				</p>
				<!--{{doc}}-->
			</li>
		{/each}
	</ul>
</UtilModal>
