<script>
  import { onDestroy } from "svelte";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import { taskExeBusinessObject } from "../task-exe-store";
  import apiService from "../../../services/api.service";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let tplparents = [];
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let value;
  let myVisibility;
  const pathToStore = tplparents.join("_") || "tpl-main";

  $: checkVisibility(visibilityFromParent, myVisibility);

  const taskExeBusinessObjectUnsubscribe = taskExeBusinessObject.subscribe(
    (bo) => {
      if (!bo) return;
      value = apiService.getNestedFromPath(bo[pathToStore], props.varName);

      if (props.visibilityVar) {
        myVisibility = apiService.getNestedFromPath(
          bo[pathToStore],
          props.visibilityVar,
        );
      }
    },
  );

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
  });

  /**
   * @param {string} parentVisibility
   * @param {string} elVisibility
   */
  function checkVisibility(parentVisibility, elVisibility) {
    myVisibility = apiService.check.widgetVisibility(
      parentVisibility,
      elVisibility,
      props,
    );
  }
</script>

{#if myVisibility !== "hidden"}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div id={item.id} class="mb-3">
    <!-- svelte-ignore a11y-label-has-associated-control -->
    <label class="form-label d-block">
      <TaskExeTplI18nLabel bind:props />
    </label>
    <span class={props.class || ""} style={props.style}>{value || ""}</span>
  </div>
{/if}
<!-- BO CURRENT: <br />{JSON.stringify($dynamicBoStore)} -->
<!-- <hr> -->
<!-- BO LOCAL: <br>{JSON.stringify($taskExeBusinessObject)} -->
<!-- <hr> -->
<!-- SBO LOCAL 1: <br>{JSON.stringify(sbo1)} -->
<!-- <hr> -->
<!-- SBO LOCAL 2: <br>{JSON.stringify(sbo2)} -->
<!-- <hr> -->
<!-- SBO LOCAL 3: <br>{JSON.stringify(sbo3)} -->
<!-- <hr> -->
<!-- SBO LOCAL 4: <br>{JSON.stringify(sbo4)} -->
<!-- <hr> -->
