import { toasts } from "svelte-toasts"
import { i18n } from "./i18n.service"
import { themeStore } from "../stores";
import { get } from "svelte/store";
import { isNumber } from "underscore";

export default {
  msg: showMsg,
  error,
  success,
  info,
  warning,
}

/** @param {{type: string; title?: string; msg: string; timeout?: number; placement?: string;}} settings
  */
function showMsg({ type, title, msg, timeout, placement }) {
  if (!type) type = 'info';
  let sendMessage = {
    title: !title ? i18n('generic.' + type) : title,
    description: msg,
    duration: isNumber(timeout) ? timeout : 3000,
    theme: get(themeStore) || 'light',
  };
  if (placement) sendMessage.placement = placement;
  return toasts[type](sendMessage)
}

/**
  * @param {{title?: string; msg: string; timeout?: number; placement?: string;}} settings
  */
function error({ title, msg, timeout, placement }) {
  return showMsg({ type: 'error', title, msg, timeout, placement })
}

/**
  * @param {{title?: string; msg: string; timeout?: number; placement?: string;}} settings
  */
function success({ title, msg, timeout, placement }) {
  return showMsg({ type: 'success', title, msg, timeout, placement })
}

/**
  * @param {{title?: string; msg: string; timeout?: number; placement?: string;}} settings
  */
function info({ title, msg, timeout, placement }) {
  return showMsg({ type: 'info', title, msg, timeout, placement })
}

/**
  * @param {{title?: string; msg: string; timeout?: number; placement?: string;}} settings
  */
function warning({ title, msg, timeout, placement }) {
  return showMsg({ type: 'warning', title, msg, timeout, placement })
}


