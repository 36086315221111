import { writable } from "svelte/store";
import aiService from "./ai.service";

function createAiTasksStore() {
	const { subscribe, set, update } = writable();
	return {
		subscribe,
		refresh: (query) => {
			aiService
				.getTaskSummary(query)
				.then(res => {
					if (res.data?.description) set(res.data);
					else set();
				})
				.catch(err => console.error(err))
		}
	}
}
export const aiTasks = createAiTasksStore();

