import { get } from "svelte/store";
import apiService from "../../services/api.service"
import { taskExeBusinessObject, taskExeErrorStore } from "./task-exe-store";
import { getValueFromI18n } from "../../services/i18n.service";
const WIDGETS_WITHOUT_VALIDATION = ['layout-container', 'layout-row', 'layout-column', 'panel', 'modal', 'button'];
const PROPS_WITHOUT_VALIDATION = ['hidden', 'disabled'];

export default {
  checkValidation,
  checkValidationForAllFields,
  getErrorVars,
}

/**
 * @param {string} boPath
 * @param {any} props
 * @returns {{hasError: boolean; showHelp: boolean; boPath: string;}} result
 */
function getErrorVars(boPath, props) {
  let errStore = get(taskExeErrorStore)[boPath];
  if (!errStore) errStore = { hasError: false, errors: [] };
  let result = { hasError: false, showHelp: false, boPath: null };

  if (["hidden", "disabled"].includes(props.visibility)) {
    result.hasError = false;
    result.showHelp = false;
    result.boPath = boPath;
    return result;
  }
  const isRequired = props.required;
  const errExists = errStore.errors.includes(props.id);
  const hasErrMessage = apiService.check.val(
    getValueFromI18n(props, 'validationMsg')
  );
  // INFO: removed isRequired .. but need to check more 
  // scenarios into the future
  result.hasError = /* isRequired && */ errExists;
  result.showHelp = /* isRequired && */ errExists && hasErrMessage;
  result.boPath = boPath;
  return result;
}

/**
 * @param {any} boPath
 * @param {{ [s: string]: any; }} taskObjects
 */
function checkValidationForAllFields(boPath, taskObjects) {
  // INFO: taskObjects is specific to templateId from 
  // which validation is made!!!
  Object.entries(taskObjects).forEach(([_key, props]) => {
    checkValidation(props, boPath);
  })
}

/**
 * @param {any} props
 * @param {any} [boPath]
 */
function checkValidation(props, boPath) {
  if (!boPath) boPath = 'tpl-main';
  let bo = get(taskExeBusinessObject)[boPath];

  if (!props) return;
  if (WIDGETS_WITHOUT_VALIDATION.includes(props.type)) return;
  if (PROPS_WITHOUT_VALIDATION.includes(props?.visibility)) {
    // remove error if exists:
    taskExeErrorStore.remove(props.id, boPath);
    return;
  }
  if (!props.required) {
    taskExeErrorStore.remove(props.id, boPath);
    return;
  }

  // remove all validations:
  //taskExeErrorStore.clear(boPath);
  //taskExeErrorStore.reset(boPath);

  // check if it's required:
  const isRequired = props.required;
  // check if it has validation rule:
  const validationRule = props.validation;
  // get obj variable:
  const val = apiService.getNestedFromPath(bo, props.varName);
  // test against rules (for textarea put multiline):
  const rex = new RegExp(validationRule, 'g' + ['textarea', 'wysiwyg'].includes(props.type) ? 'm' : '');
  const resRex = rex.test(val);
  const resReq = (isRequired === true ? (!apiService.check.val(val) ? false : true) : true);
  const resWoErr = resRex && resReq;
  if (resWoErr === false) {
    taskExeErrorStore.add(props.id, boPath);
  } else {
    taskExeErrorStore.remove(props.id, boPath);
  }
}

