<script>
  import { onDestroy, onMount } from "svelte";
  import Chart from "./../../utils/chart.svelte";
  import apiService from "../../../services/api.service";
  import { taskExeBusinessObject } from "../task-exe-store";
  import { get } from "svelte/store";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let tplparents = [];
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let value;
  let myVisibility;
  const pathToStore = tplparents.join("_") || "tpl-main";
  const types = ["line", "bar", "pie"];
  let config = undefined;
  let config1 = {
    type: props?.chartType || types[randomIntFromInterval(0, 2)],
    data: {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      datasets: [
        {
          label: "Dataset 1",
          data: getRandomNumber(),
          // data: [10, 50, 30, 100, 90, 40, 70],
          // borderColor: Utils.CHART_COLORS.red,
          // backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5),
        },
        {
          label: "Dataset 2",
          data: getRandomNumber(),
          // data: [70, 40, 90, 100, 30, 50, 10],
          // borderColor: Utils.CHART_COLORS.blue,
          // backgroundColor: Utils.transparentize(Utils.CHART_COLORS.blue, 0.5),
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: props.label || "Chart.js Chart",
        },
      },
    },
  };

  // $: changedChartType(props?.chartType);
  // $: changedChartLabel(props?.label);
  $: checkVisibility(visibilityFromParent, myVisibility);

  const taskExeBusinessObjectUnsubscribe = taskExeBusinessObject.subscribe(
    (bo) => {
      if (!bo) return;
      const newTempValue = apiService.getNestedFromPath(
        bo[pathToStore],
        props.varName,
      );
      if (value !== newTempValue) value = newTempValue;

      if (value) config = value;
      else config = config1;

      if (props.visibilityVar) {
        myVisibility = apiService.getNestedFromPath(
          bo[pathToStore],
          props.visibilityVar,
        );
      }
    },
  );

  onMount(() => {
    const firstValue = apiService.getNestedFromPath(
      get(taskExeBusinessObject)[pathToStore],
      props.varName,
    );
    if (firstValue) config = firstValue;
    taskExeBusinessObject.ensureValue(
      pathToStore,
      props.varName,
      firstValue,
      true,
    );
  });

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
  });

  function changedChartLabel(label) {
    // console.log("... label");
    config.options.plugins.title.text = label || "Chart.js Chart";
  }

  function changedChartType(type) {
    config.type = type || types[randomIntFromInterval(0, 2)];
  }

  function getRandomNumber() {
    const myData = Array(7)
      .fill(1)
      .map(() => {
        return randomIntFromInterval(0, 100);
      });
    return myData;
  }

  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  /**
   * @param {string} parentVisibility
   * @param {string} elVisibility
   */
  function checkVisibility(parentVisibility, elVisibility) {
    myVisibility = apiService.check.widgetVisibility(
      parentVisibility,
      elVisibility,
      props,
    );
  }
</script>

{#if myVisibility !== "hidden"}
  <div id={item.id} class="mb-3">
    {#if config?.type}
      <div class={props.class || ""} style={props.style}>
        <Chart bind:type={config.type} bind:config />
      </div>
    {/if}
  </div>
{/if}

<!-- {JSON.stringify(config)} -->
<!-- <hr /> -->
<!-- {JSON.stringify($taskExeBusinessObject)} -->
