import axios from "axios"

export default {
  uploadFile,
  getDocuments,
  remDocument,
  getVersions,
  cmis: {
    open: cmis_open,
    delete: cmis_delete,
  },
}

/**
 * @param {any} formData
 * @param {any} uploadProgress
 */
function uploadFile(formData, uploadProgress) {
  return axios.post('/api/upload/files', formData, { headers: { "Content-Type": "multipart/form-data" }, onUploadProgress: uploadProgress })
}

/**
 * @param {any} query
 */
function getDocuments(query) {
  return axios.get('/api/rest/bo/document', { params: query });
}

/**
 * @param {string} id
 */
function remDocument(id) {
  return axios.delete('/api/rest/bo/document/' + id);
}

/**
 * @param {string} docId
 */
function getVersions(docId) {
  return axios.get('/api/rest/bo/docversions', { params: { docId } });
}

/**
 * @param {any} query
 */
function cmis_open(query) {
  return axios.get('/cmis/open', { params: query, responseType: 'blob' });
}

/**
 * @param {any} query
 * @param {any} body
 */
function cmis_delete(query, body) {
  return axios.post('/cmis/deleteDocument', body || {}, { params: query });
}

