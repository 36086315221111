<script>
  import { onDestroy, onMount } from "svelte";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import { taskExeBusinessObject, taskExeErrorStore } from "../task-exe-store";
  import taskExeApi from "../task-exe.api";
  import { isLoading } from "../../../stores";
  import dayjs from "dayjs";
  import apiService from "../../../services/api.service";
  import { currentLanguage } from "../../../services/i18n.service";
  import taskExeErrorService from "../task-exe-error.service";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let tplparents = [];
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let value, date, time;
  let hasError = false;
  let showHelp = false;
  let myVisibility;
  const pathToStore = tplparents.join("_") || "tpl-main";

  const FORMATS = {
    DATE: "YYYY-MM-DD",
    TIME: "HH:mm",
  };
  const widgetId = `${pathToStore ? pathToStore + "_" + item.id : item.id}`;

  $: checkVisibility(visibilityFromParent, myVisibility);

  const taskExeBusinessObjectUnsubscribe = taskExeBusinessObject.subscribe(
    (bo) => {
      if (!bo) return;
      value = apiService.getNestedFromPath(bo[pathToStore], props.varName);
      if (value) {
        const newDate = dayjs(value);
        if (newDate.isValid()) {
          date = newDate.format(FORMATS.DATE);
          time = newDate.format(FORMATS.TIME);
        }
      }

      if (props.visibilityVar) {
        myVisibility = apiService.getNestedFromPath(
          bo[pathToStore],
          props.visibilityVar,
        );
      }
    },
  );

  const taskExeErrorStoreUnsubscribe = taskExeErrorStore.subscribe(() => {
    const resultErrVars = taskExeErrorService.getErrorVars(pathToStore, props);
    if (resultErrVars?.boPath === pathToStore) {
      hasError = resultErrVars.hasError;
      showHelp = resultErrVars.showHelp;
    }
  });

  onMount(() => {
    valueChanged(true);
  });

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
    taskExeErrorStoreUnsubscribe();
  });

  /**
   * @param {boolean} [init]
   */
  function valueChanged(init) {
    const isInit = init === true;

    if (!time && date) {
      value = dayjs(`${date}`, "DD.MM.YYYY");
    } else if (!date && time) {
      const timeArr = time.split(":");
      value = dayjs().set("hour", timeArr[0]).set("minute", timeArr[1]);
    } else {
      value = dayjs(`${date} ${time}`, "DD.MM.YYYY HH:mm");
    }
    value = value.toDate();
    taskExeBusinessObject.ensureValue(
      pathToStore,
      props.varName,
      value,
      isInit,
    );

    if (isInit) return;

    // INFO: Keep this here to not show invalid state on first rendering
    taskExeErrorService.checkValidation(props, pathToStore);

    taskExeApi.submit({
      id: item.id,
      boPath: pathToStore,
      tplId: templateProps?.tplId,
    });
  }

  /**
   * @param {string} parentVisibility
   * @param {string} elVisibility
   */
  function checkVisibility(parentVisibility, elVisibility) {
    myVisibility = apiService.check.widgetVisibility(
      parentVisibility,
      elVisibility,
      props,
    );
  }
</script>

{#if myVisibility !== "hidden"}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div id={item.id} class="mb-3">
    <label class="form-label d-block" for="dt_{widgetId}">
      <TaskExeTplI18nLabel bind:props />{props.required ? "*" : ""}
    </label>
    {#if props.datetimeType === "date"}
      <input
        id="dt_{widgetId}"
        type="date"
        class="form-control {props.class || ''}"
        class:is-invalid={hasError}
        style={props.style}
        aria-labelledby={props.label_i18n
          ? props.label_i18n[$currentLanguage]
          : props.label}
        max={props.maxDate}
        min={props.minDate}
        bind:value={date}
        on:change={() => {
          valueChanged();
        }}
        disabled={myVisibility === "disabled" || $isLoading}
      />
    {:else if props.datetimeType === "time"}
      <input
        id="dt_{widgetId}"
        type="time"
        class="form-control {props.class || ''}"
        class:is-invalid={hasError}
        style={props.style}
        aria-labelledby={props.label_i18n
          ? props.label_i18n[$currentLanguage]
          : props.label}
        max={props.maxDate}
        min={props.minDate}
        bind:value={time}
        on:change={() => {
          valueChanged();
        }}
        disabled={myVisibility === "disabled" || $isLoading}
      />
    {:else}
      <input
        id="dtd_{widgetId}"
        type="date"
        class="form-control d-inline-block w-auto {props.class || ''}"
        class:is-invalid={hasError}
        style={props.style}
        aria-labelledby={props.label_i18n
          ? props.label_i18n[$currentLanguage]
          : props.label}
        max={props.maxDate}
        min={props.minDate}
        bind:value={date}
        on:change={() => {
          valueChanged();
        }}
        disabled={myVisibility === "disabled" || $isLoading}
      />
      <input
        id="dtt_{widgetId}"
        type="time"
        class="form-control d-inline-block w-auto {props.class || ''}"
        class:is-invalid={hasError}
        style={props.style}
        aria-labelledby={props.label_i18n
          ? props.label_i18n[$currentLanguage]
          : props.label}
        max={props.maxDate}
        min={props.minDate}
        bind:value={time}
        on:change={() => {
          valueChanged();
        }}
        disabled={myVisibility === "disabled" || $isLoading}
      />
    {/if}

    {#if showHelp}
      <div id={props.id + "-HelpBlock"} class="invalid-feedback d-block">
        {props?.validationMsg_i18n?.[$currentLanguage]
          ? props.validationMsg_i18n[$currentLanguage]
          : props.validationMsg}
      </div>
    {/if}
  </div>
{/if}
