import { writable } from "svelte/store"
import apiService from "../../services/api.service";
import taskExeApi from "./task-exe.api";

const MAIN_TPL = 'tpl-main';

function createTaskExeStore() {
  const { subscribe, set, update } = writable();
  return {
    subscribe,
    set,
    update,
  }
}

export const taskExeStore = createTaskExeStore();

export const taskExeTitle = writable();

// Used for Business Local Object throughout the UI:
function createTaskExeBusinessObject() {
  // set main bo:
  const { subscribe, set, update } = writable({ [MAIN_TPL]: {} });
  let boInit;
  return {
    subscribe,
    //set,
    update,
    clear: () => {
      set({ [MAIN_TPL]: {} });
    },
    // TODO: Change updateBO cu updateBO for init si updateBOFromHtmlWidget
    // The last one requires updating the other templates while the first one
    // requires onlu the main template
    updateBO: (/** @type {string} */ template, /** @type {object | undefined} */ businessObject) => {
      //console.log('... update bo', template, businessObject)
      const maps = taskExeApi.getTemplatesMap();
      //console.log('... maps', maps)
      update(bo => {
        const boName = template || MAIN_TPL;
        if (!bo) bo = { [MAIN_TPL]: {} };
        //console.log('... update', boName, bo, businessObject)
        if (!bo[boName]) bo[boName] = {};
        bo[boName] = businessObject || {};
        // INFO: Update can also come from html widgets that
        // requires to update also the other templates'bos
        if (maps?.[boName] && bo?.[boName])
          updateTplObj(maps[boName], bo, []);
        return bo;
      })
    },
    updateInit: () => {
      // INFO: get all template in order to find the MAIN_TPL:
      const maps = taskExeApi.getTemplatesMap();
      //console.log('... maps', maps);
      //set(boInit);
      setTimeout(() => {
        //boInit = null;
        update(bo => {
          //console.log('... bo init...', JSON.stringify(bo))
          updateTplObj(maps[MAIN_TPL], bo, []);
          //console.log('... bo final...', JSON.stringify(bo))
          return bo;
        })
      })
    },
    ensureValue: (/** @type {string} */ whichBo, /** @type {string} */ pathBo, /** @type {any} */ value, /** @type {Boolean} */ init) => {
      // INFO: The init value is used to gather all variables before update
      // in store. That should count to performance improvement
      // JUST take into account that in widgets only on mount should
      // ensure variable with init
      if (false) {
        if (!pathBo) return;
        if (!whichBo) whichBo = MAIN_TPL;
        if (!boInit) {
          boInit = get(taskExeBusinessObject) || {};
        }
        if (!boInit[whichBo]) boInit[whichBo] = {};
        const pathArray = pathBo.split('.');
        const parthArrayLen = pathArray.length;
        pathArray.reduce((obj, path, pathIx) => {
          let element;
          if (path) {
            if (pathIx === parthArrayLen - 1) {
              if (!obj[path]) obj[path] = value;
              else obj[path] = value;
            } else {
              if (!obj[path]) obj[path] = {};
            }
            element = obj[path];
          } else {
            element = obj;
          }
          return element;
        }, boInit[whichBo]);
      } else {
        ensureValueFn(update, whichBo, pathBo, value, init);
      }
    }
  }
}

/**
 * @param {Function} update
 * @param {string} whichBo
 * @param {string} pathBo
 * @param {any} value
 * @param {boolean} init
 */
function ensureValueFn(update, whichBo, pathBo, value, init) {
  //console.log('... ensure value')
  if (!pathBo) return;
  if (!whichBo) whichBo = MAIN_TPL;
  const maps = taskExeApi.getTemplatesMap();
  const currentTemplate = maps[whichBo];
  update(bo => {
    if (!bo[whichBo]) bo[whichBo] = {};
    const pathArray = pathBo.split('.');
    const parthArrayLen = pathArray.length;
    pathArray.reduce((obj, path, pathIx) => {
      let element;
      if (path) {
        if (pathIx === parthArrayLen - 1) {
          if (!obj[path]) obj[path] = value;
          else obj[path] = value;
        } else {
          if (!obj[path]) obj[path] = {};
        }
        element = obj[path];
      } else {
        element = obj;
      }
      return element;
    }, bo[whichBo]);
    if (!init) {
      // update all parents and children:
      updateTplObj(currentTemplate, bo, []);
    }
    return bo;
  });

}

/**
 * @param {{ path: any; children: any[]; parents: string | any[]; props: { io: { in: any[]; }; }; }} currentTemplate
 * @param {{ [x: string]: any; "tpl-main"?: {}; }} bo
 * @param {any[]} arrToExclude
 */
function updateTplObj(currentTemplate, bo, arrToExclude) {
  //console.log('... update tpl obj', currentTemplate, arrToExclude)
  if (arrToExclude.includes(currentTemplate.path)) return;
  // update all children of current template:
  if (currentTemplate.children?.length > 0) {
    const tplParentId = currentTemplate.path;
    currentTemplate.children.forEach(tpl => {
      //console.log('... try update child::', tpl)
      const tplChildId = tpl.path;
      if (!bo[tplChildId]) bo[tplChildId] = {};
      if (tpl?.props?.io?.in?.length > 0) {
        tpl.props.io.in.forEach(m => {
          // TODO: check to only modify the changed variable!!!
          const varChild = m.var;
          const varParent = m.varMapped;
          const valueChild = apiService.getNestedFromPath(bo[tplChildId], varChild);
          const valueParent = apiService.getNestedFromPath(bo[tplParentId], varParent);
          //console.log('... varchild', varChild, bo[tplChildId]?.[varChild]);
          //console.log('... varparent', varParent, bo[tplParentId]?.[varParent]);

          if (varChild && varParent) {
            //console.log('... child', tplChildId, varChild, varParent, valueParent)
            apiService.check.ensureValue(bo[tplChildId], varChild, valueParent)
          }
        })
      }
      arrToExclude.push(currentTemplate.path);
      if (tpl.children?.length > 0) {
        updateTplObj(tpl, bo, arrToExclude);
      }
    })
  }

  if (currentTemplate?.parents?.length > 0) {
    //console.log('... checking parents', currentTemplate)
    const tplChildId = currentTemplate.path;
    if (currentTemplate?.props?.io?.in?.length > 0) {
      currentTemplate.props.io.in.forEach(m => {
        // TODO: check to only modify the changed variable!!!
        const varChild = m.var;
        const varParent = m.varMapped;
        const valueChild = apiService.getNestedFromPath(bo[tplChildId], varChild);

        // INFO: Update only the 1st parent!!!
        if (varChild && varParent) {
          const firstParent = currentTemplate.parents[currentTemplate.parents.length - 1];
          //console.log('... first parent', firstParent, currentTemplate.parents)
          if (!firstParent) return;
          const tplParentId = firstParent.path;
          const valueParent = apiService.getNestedFromPath(bo[tplParentId], varParent);
          //console.log('... varchild parent:::', varChild, valueChild);
          //console.log('... varparent parent:::', varParent, valueParent);

          apiService.check.ensureValue(bo[tplParentId], varParent, valueChild);

          arrToExclude.push(currentTemplate.path);
          if (firstParent.parents?.length > 0) {
            updateTplObj(firstParent, bo, arrToExclude);
          }
        }
      })
    }
  }
}

export const taskExeBusinessObject = createTaskExeBusinessObject();


export const taskExeInfo = writable();

function createTaskExeErrorStore() {
  const { subscribe, set, update } = writable({ 'tpl-main': { hasErrors: false, errors: [] } });
  return {
    subscribe,
    clear: (/** @type {string} */ boPath) => {
      update(objInit => {
        if (!objInit[boPath]) objInit[boPath] = { hasErrors: false, errors: [] };
        const obj = objInit[boPath];
        obj.errors = [];
        obj.hasErrors = false;
        return objInit;
      })
    },
    addIds: (/** @type {string[]} */ ids, /** @type {string} */ boPath) => {
      update(objInit => {
        if (!objInit[boPath]) objInit[boPath] = { hasErrors: false, errors: [] };
        const obj = objInit[boPath];
        ids.forEach(id => {
          if (!obj.errors.includes(id)) obj.errors.push(id);
        })
        obj.hasErrors = obj.errors.length > 0;
        return objInit;
      })
    },
    add: (/** @type {string} */ id, /** @type {string} */ boPath) => {
      update(objInit => {
        if (!objInit[boPath]) objInit[boPath] = { hasErrors: false, errors: [] };
        const obj = objInit[boPath];
        if (!obj.errors.includes(id)) obj.errors.push(id);
        obj.hasErrors = obj.errors.length > 0;
        return objInit;
      })
    },
    remove: (/** @type {string} */ id, /** @type {string} */ boPath) => {
      update(objInit => {
        if (!objInit[boPath]) objInit[boPath] = { hasErrors: false, errors: [] };
        const obj = objInit[boPath];
        const index = obj.errors.findIndex(el => el === id);
        if (index > -1) obj.errors.splice(index, 1);
        obj.hasErrors = obj.errors.length > 0;
        return objInit;
      })

    },
    reset: () => { set({ "tpl-main": { hasErrors: false, errors: [] } }) }
  }
}

export const taskExeErrorStore = createTaskExeErrorStore();

function createTaskExeCommand() {
  const { subscribe, set } = writable();
  return {
    subscribe,
    command: (action) => {
      set(action);
      set();
    }
  }
}

export const taskExeCommandStore = createTaskExeCommand();
