import axios from "axios"

export default {
	getTaskSummary,
}

/**
 * @param {any} query
 */
function getTaskSummary(query) {
	return axios.post("/api/ai/azure/tasks/summary", {}, { params: query });
}
