<script>
  import { onDestroy } from "svelte";
  import TaskExeCompList from "../task-exe-comp-list.svelte";
  import { taskExeBusinessObject } from "../task-exe-store";
  import apiService from "../../../services/api.service";

  /** @type {{id: string; columns: any[];}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = null;
  export let tplparents = [];
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string}|null} */
  export let templateProps = null;

  const pathToStore = tplparents.join("_") || "tpl-main";

  let xl = 12,
    lg = 12,
    md = 12,
    sm = 12;
  let hasError = false;
  let currentParents = [];
  let myVisibility;

  $: changedSize(props.size);

  $: checkVisibility(visibilityFromParent, myVisibility);

  const taskExeBusinessObjectUnsubscribe = taskExeBusinessObject.subscribe(
    (bo) => {
      if (!bo) return;
      if (props.visibilityVar) {
        myVisibility = apiService.getNestedFromPath(
          bo[pathToStore],
          props.visibilityVar,
        );
      }
    },
  );

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
  });

  /**
   * @param {string} size
   */
  function changedSize(size) {
    if (!size) size = ",,,";
    // if (!size) {
    //   props.size = "12,12,12,12";
    //   return;
    // };
    const arr = size.split(",");
    xl = +arr[0];
    lg = +arr[1];
    md = +arr[2];
    sm = +arr[3];
  }

  /**
   * @param {string} parentVisibility
   * @param {string} elVisibility
   */
  function checkVisibility(parentVisibility, elVisibility) {
    //console.log(
    //  "... update visibility",
    //  props.id,
    //  parentVisibility,
    //  elVisibility,
    //);
    myVisibility = apiService.check.widgetVisibility(
      parentVisibility,
      elVisibility,
      props,
    );
  }
</script>

<!-- COLUMN: parent: {visibilityFromParent} / item: {myVisibility} -->
{#if myVisibility !== "hidden"}
  <div
    id={item.id}
    class="{props.size && xl ? 'col-xl-' + xl : ''} {props.size && lg
      ? 'col-lg-' + lg
      : ''} {props.size && md ? 'col-md-' + md : ''} {props.size && sm
      ? 'col-sm-' + sm
      : ''} {props.class || ''}"
    class:col={!props.size}
    style={props.style}
  >
    <!-- COLUMN ({item.id}): {myVisibility} / {props.visibility} -->
    {#each item?.columns as child}
      <TaskExeCompList
        bind:item={child}
        bind:parent={currentParents}
        bind:tplparents
        bind:visibilityFromParent={myVisibility}
        bind:templateProps
      />
    {/each}
  </div>
{/if}
