<script>
  import { onDestroy, onMount } from "svelte";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import { taskExeBusinessObject, taskExeErrorStore } from "../task-exe-store";
  import taskExeApi from "../task-exe.api";
  import apiService from "../../../services/api.service";
  import { isLoading } from "../../../stores";
  import { currentLanguage } from "../../../services/i18n.service";
  import taskExeErrorService from "../task-exe-error.service";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let tplparents = [];
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let hasError = false;
  let showHelp = false;
  let value;
  let myVisibility;
  const pathToStore = tplparents.join("_") || "tpl-main";
  const widgetId = pathToStore ? pathToStore + "_" + item.id : item.id;

  $: checkVisibility(visibilityFromParent, myVisibility);

  const taskExeBusinessObjectUnsubscribe = taskExeBusinessObject.subscribe(
    (bo) => {
      if (!bo) return;
      value = apiService.getNestedFromPath(bo[pathToStore], props.varName);

      if (props.visibilityVar) {
        myVisibility = apiService.getNestedFromPath(
          bo[pathToStore],
          props.visibilityVar,
        );
      }
    },
  );

  const taskExeErrorStoreUnsubscribe = taskExeErrorStore.subscribe(() => {
    const resultErrVars = taskExeErrorService.getErrorVars(pathToStore, props);
    if (resultErrVars?.boPath === pathToStore) {
      hasError = resultErrVars.hasError;
      showHelp = resultErrVars.showHelp;
    }
  });

  onMount(() => {
    valueChanged(true);
  });

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
    taskExeErrorStoreUnsubscribe();
  });

  /**
   * @param {boolean} [init]
   */
  function valueChanged(init) {
    const isInit = init === true;
    taskExeBusinessObject.ensureValue(
      pathToStore,
      props.varName,
      value,
      isInit,
    );

    if (isInit) return;

    // INFO: Keep this here to not show invalid state on first rendering
    taskExeErrorService.checkValidation(props, pathToStore);

    taskExeApi.submit({
      id: item.id,
      boPath: pathToStore,
      tplId: templateProps?.tplId,
    });
  }

  /**
   * @param {string} parentVisibility
   * @param {string} elVisibility
   */
  function checkVisibility(parentVisibility, elVisibility) {
    myVisibility = apiService.check.widgetVisibility(
      parentVisibility,
      elVisibility,
      props,
    );
  }
</script>

{#if myVisibility !== "hidden"}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div id={item.id}>
    <label class="form-label d-block" for="ta_{widgetId}">
      <TaskExeTplI18nLabel bind:props />{props.required ? "*" : ""}
    </label>
    <textarea
      id="ta_{widgetId}"
      class="form-control {props.class || ''}"
      class:is-invalid={hasError}
      class:mb-3={!showHelp}
      style={props.style}
      placeholder={props.placeholder_i18n
        ? props.placeholder_i18n[$currentLanguage]
        : props.placeholder}
      aria-labelledby={props.label_i18n
        ? props.label_i18n[$currentLanguage]
        : props.label}
      bind:value
      on:change={() => {
        valueChanged();
      }}
      disabled={myVisibility === "disabled" || $isLoading}
    />

    {#if showHelp}
      <div id={props.id + "-HelpBlock"} class="invalid-feedback mb-3">
        {props?.validationMsg_i18n?.[$currentLanguage]
          ? props.validationMsg_i18n[$currentLanguage]
          : props.validationMsg}
      </div>
    {/if}
  </div>
{/if}
