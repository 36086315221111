<script>
  import _ from "underscore";
  import TaskExeTplHtmlJs from "./task-exe-tpl-html-js.svelte";
  import TaskExeTplHtmlAngularjs from "./task-exe-tpl-html-angularjs.svelte";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string}[]} */
  export let parent = undefined;
  export let tplparents = [];
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;
  export let visibilityFromParent = "editable";

  let pathToItem = tplparents.join("_");
</script>

<!-- HTML PROPS: {JSON.stringify(props)}<br /> -->
<!-- HTML TPLPARENTS: {JSON.stringify(tplparents)} / {parent}<br /> -->
<div>
  {#if props.useAngularJS}
    <!-- html-angularjs -->
    <TaskExeTplHtmlAngularjs
      bind:item
      bind:props
      bind:parent
      bind:tplparents
      bind:templateProps
      bind:visibilityFromParent
    ></TaskExeTplHtmlAngularjs>
  {:else}
    <!-- html-js -->
    <TaskExeTplHtmlJs
      bind:item
      bind:props
      bind:parent
      bind:tplparents
      bind:templateProps
      bind:visibilityFromParent
    ></TaskExeTplHtmlJs>
  {/if}
</div>
