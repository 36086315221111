<script>
  import { onDestroy, onMount } from "svelte";
  import ConnectionDiaSettings from "../components/connection/connection-dia-settings.svelte";
  import connectionService from "../components/connection/connection.service";
  import UtilCardTitle from "../components/utils/util-card-title.svelte";
  import UtilPagination from "../components/utils/util-pagination.svelte";
  import UtilSearch from "../components/utils/util-search.svelte";
  import UtilTable from "../components/utils/util-table.svelte";
  import constsService from "../services/consts.service";
  import { langChanged, t, tNow } from "../services/i18n.service";
  import toasterService from "../services/toaster.service";
  let itemsNo = 0;
  let items = [];
  let modalConnectionSettings;
  let query = {
    sort: "-timestamp",
    limit: constsService.QUERY_PAGE_LIMIT,
    skip: 0,
  };
  let isLoading = false;

  const langChangedUnsubscribe = langChanged.subscribe(() => {
    transformData(items);
    items = [...items];
  });

  onMount(() => {
    search();
  });

  onDestroy(() => {
    langChangedUnsubscribe();
  });

  function search() {
    countItems();
    getItems();
  }

  /**
   * @param {any[]} data
   */
  function transformData(data) {
    data.forEach((el) => {
      el.kindTranslated = tNow("generic." + el.kind);
    });
  }

  function countItems() {
    connectionService
      .count(query)
      .then((res) => {
        itemsNo = res.data[0];
      })
      .catch((err) => {
        console.error(err.error);
      });
  }

  function getItems() {
    isLoading = true;
    connectionService
      .get(query)
      .then((res) => {
        // items = transformData(res.data);
        items = res.data;
        transformData(items);
        isLoading = false;
      })
      .catch((err) => {
        toasterService.msg({ type: "error", msg: err.error });
        isLoading = false;
      });
  }

  function editShow({ item }) {
    modalConnectionSettings.openExistingItem(item._id, item.pc_id);
  }
</script>

<div class="card">
  <div class="card-header bg-primary">
    <UtilCardTitle
      title={$t("conn.title")}
      desc={$t("home.admin.conn.desc") +
        " | " +
        $t("generic.total") +
        ": " +
        (itemsNo || 0)}
    />
  </div>
  <div class="card-body">
    <UtilSearch
      bind:query
      queryFn={search}
      fields={[
        {
          lbl: $t("generic.type"),
          ph: null,
          path: "kind",
          primary: true,
          secondary: true,
        },
        {
          lbl: $t("generic.name"),
          ph: null,
          path: "name",
          primary: true,
          secondary: true,
        },
      ]}
    />

    <UtilTable
      data={items}
      bind:query
      queryFn={search}
      bind:dataLoading={isLoading}
      setts={{
        conf: {
          header: {},
          items: {
            edit: { show: true },
          },
        },
        cols: [
          {
            lbl: $t("generic.name"),
            path: "name",
            type: "string",
            sort: true,
            class: null,
          },
          {
            lbl: $t("generic.type"),
            path: "kindTranslated",
            sort: true,
            type: "string",
            class: null,
          },
          {
            lbl: $t("generic.desc"),
            path: "desc",
            type: "string",
            sort: false,
            class: null,
          },
          {
            lbl: $t("generic.date"),
            path: "timestamp",
            type: "date",
            sort: true,
            class: null,
          },
        ],
      }}
      on:edit={(ev) => editShow({ item: ev.detail })}
    />

    <UtilPagination
      bind:query
      bind:totalData={itemsNo}
      on:callData={getItems}
    />
  </div>
</div>

<ConnectionDiaSettings
  bind:this={modalConnectionSettings}
  on:callback={search}
/>
