<script>
  import { querystring } from "svelte-spa-router";
  import { onDestroy, onMount } from "svelte";
  import TaskExeCompList from "../components/task/task-exe-comp-list.svelte";
  import {
    taskExeBusinessObject,
    taskExeCommandStore,
    taskExeInfo,
    taskExeStore,
  } from "../components/task/task-exe-store";
  import { parse as qsParse } from "qs";
  import taskExeApi from "./../components/task/task-exe.api";
  import { t } from "../services/i18n.service";
  import { Wave } from "svelte-loading-spinners";
  import apiService from "../services/api.service";

  export let isComponent = false;
  export let params = {};
  export let myParams = {};
  let queryParams = {};
  let init = true;
  let querystringUnsubscribe;

  const taskExeCommandStoreUnsubscribe = taskExeCommandStore.subscribe(
    (res) => {
      if (res?.action === "open") {
        taskExeApi.clear({ status: "tranziting" });
        taskExeApi.init(
          res.details.details.tid,
          res.details.details.dashboard,
          true,
        );
      }
    },
  );

  //const taskExeBusinessObjectUnsubscribe = taskExeBusinessObject.subscribe(
  //  (bo) => {
  //    if (!$taskExeStore) return;
  //    if ($taskExeInfo?.status !== "loaded") return;
  //    debounceCalculateVisibility("general", bo);
  //  },
  //);

  onMount(() => {
    // console.log(">>>> TASK ON MOUNT");
    // taskExeApi.clear();

    querystringUnsubscribe = querystring.subscribe((res) => {
      if (!isComponent) {
        queryParams = qsParse(res);

        // setTimeout(() => {
        init = false;
        // }, 200);
      } else {
        init = false;
        if (!params) params = {};
        if (!queryParams) queryParams = {};
        params.tid = myParams.tid;
        queryParams.dashboard = myParams.dashboard;
      }
    });

    // NOTE: next line shouldn't be moved inside querystring.subscribe !!!
    // We need at list params.tid in order to start the task;
    if (!apiService.check.val(params.tid)) return;
    taskExeApi.init(params.tid, queryParams.dashboard);
  });

  onDestroy(() => {
    taskExeCommandStoreUnsubscribe();
    querystringUnsubscribe();
    //taskExeBusinessObjectUnsubscribe();
    setTimeout(() => {
      taskExeApi.clear();
    }, 300);
  });
</script>

<!-- {#if !init} -->
<!-- {#key $taskExeStore?.status} -->

<!-- {#key params?.tid} -->
<div id="tskcontainer">
  {#if $taskExeInfo?.status === "loading"}
    <div
      class="d-flex position-absolute top-50 start-50 translate-middle text-muted fs-3 text-center"
    >
      <Wave color="#17575e" size="30" unit="px" duration="1s" />
      <span class="ms-3">
        {$t("generic.taskLoading")}
      </span>
    </div>
  {/if}
  {#if $taskExeInfo?.status === "tranziting"}
    <div class="text-muted">
      <span class="ms-3">
        {$t("generic.pleaseWait")}
      </span>
    </div>
  {/if}
  {#if !init && $taskExeInfo?.status === "closed"}
    <div class="text-muted">{$t("generic.taskClosed")}</div>
  {/if}
  {#if $taskExeInfo?.status === "loaded"}
    {#each $taskExeStore?.definition?.dropzone?.Dropzone || [] as item}
      <TaskExeCompList
        bind:item
        parent={[]}
        tplparents={[]}
        visibilityFromParent="editable"
      />
    {/each}
  {/if}
  {#if $taskExeInfo?.status === "tranziting"}
    <div
      class="d-flex position-absolute top-50 start-50 translate-middle text-muted fs-3 text-center"
    >
      <Wave color="#17575e" size="30" unit="px" duration="1s" />
      <span class="ms-3">
        {$t("generic.taskLoading")}
      </span>
    </div>
  {/if}
</div>
<br />
<br />
<br />
<!-- {/key} -->
<!-- {/if} -->

<!-- <hr /> -->
<!-- <pre>{JSON.stringify($taskExeInfo, null, 2)}</pre> -->
<!-- <pre>{JSON.stringify($taskExeBusinessObject, null, 2)}</pre> -->
<!-- <pre>{JSON.stringify($taskExeStore, null, 2)}</pre> -->
