import axios from "axios";
import { derived, writable, get as getFromStore } from "svelte/store";
import { get } from 'underscore';
import api from "../services/api.service";
import storageService from "./storage.service";

const RTL = {
  he: { dir: 'rtl', lang: 'he' },
}
const FILE_LANG_EXCEPTIONS = ['he'];

let json;
let langDefault = 'en';
export let langChanged = writable();
export const currentLanguage = writable('en');
export const textDirection = writable();
langChanged.subscribe(res => {
  json = res;
  currentLanguage.set(langDefault)
  let textDir = RTL[langDefault] || { dir: 'ltr', lang: langDefault };
  textDirection.set(textDir);
})
export const locale = writable("en");
export const langs = {
  en: { lang: 'en', icon: 'GB.png', code: 'en_EN' },
  es: { lang: 'es', icon: 'ES.png', code: 'es_ES' },
  ro: { lang: 'ro', icon: 'RO.png', code: 'ro_RO' },
  he: { lang: 'he', icon: 'IL.png', code: 'he_IL' },
  bg: { lang: 'bg', icon: 'BG.png', code: 'bg_BG' },
  ca: { lang: 'ca', icon: 'ES.png', code: 'ca_CA' },
  de: { lang: 'de', icon: 'DE.png', code: 'de_DE' },
  el: { lang: 'el', icon: 'GR.png', code: 'el_EL' },
  fr: { lang: 'fr', icon: 'FR.png', code: 'fr_FR' },
  hi: { lang: 'hi', icon: 'IN.png', code: 'hi_HI' },
  hr: { lang: 'hr', icon: 'HR.png', code: 'hr_HR' },
  hu: { lang: 'hu', icon: 'HU.png', code: 'hu_HU' },
  it: { lang: 'it', icon: 'IT.png', code: 'it_IT' },
  lt: { lang: 'lt', icon: 'LT.png', code: 'lt_LT' },
  mt: { lang: 'mt', icon: 'MT.png', code: 'mt_MT' },
  no: { lang: 'no', icon: 'NO.png', code: 'no_NO' },
  pl: { lang: 'pl', icon: 'PL.png', code: 'pl_PL' },
  pt: { lang: 'pt', icon: 'PT.png', code: 'pt_PT' },
  sv: { lang: 'sv', icon: 'SE.png', code: 'sv_SV' },
  tr: { lang: 'tr', icon: 'TR.png', code: 'tr_TR' },
  uk: { lang: 'uk', icon: 'UA.png', code: 'uk_UK' },
};
//export const locales = ['en', 'es', 'ro', 'he']
export const locales = Object.keys(langs);
export const langsCodes = locales.map(key => langs[key].code);
export const i18n = translate_now;
export const tNow = translate_now;
export const changeLang = changeLangFn;
export const t = derived([locale, langChanged], ([$locale, $langChanged]) => (key, vars = {}) =>
  translate($langChanged, key, vars)
);
// export const currentLanguage = derived([locale, langChanged], ([$locale, $langChanged]) => $locale);

/**
 * @param {any} props
 * @param {string} path
 */
export function getValueFromI18n(props, path) {
  if (!path) path = 'label';
  if (props?.[path + '_i18n']?.[getFromStore(currentLanguage)])
    return props?.[path + '_i18n']?.[getFromStore(currentLanguage)];
  else return props[path];
}

getTranslation();

/**
 * @param {() => void} [next]
 */
function getTranslation(next) {
  const langSaved = storageService.data.get('lang');
  if (langSaved) {
    if (locales.includes(langSaved)) {
      langDefault = langSaved;
      locale.update(() => langDefault);
    } else {
      langDefault = 'en';
      locale.update(() => 'en');
    }
  };
  let file;
  const filename = `${langDefault}_${langDefault.toUpperCase()}.json`;
  if (!FILE_LANG_EXCEPTIONS.includes(langDefault)) {
    file = filename;
  } else {
    switch (langDefault) {
      case 'he':
        file = 'he_IL.json';
        break;
      default:
        file = 'en_EN.json';
        break;
    }
  }

  const path = 'i18n/' + file;
  axios.get(path, {
    baseURL: '/portal',
    headers: {
      accept: 'application/json'
    }
  }).then(res => {
    // INFO: this script is good for debugging :)
    //if (typeof (res.data) === 'string') {
    //  langChanged.set(JSON.parse(res.data));
    //} else {
    //  langChanged.set(res.data);
    //}
    langChanged.set(res.data);
    if (next) next();
  })
}

/**
 * @param {string} key
 * @param {{[x: string]: any;}} vars
 * @param {string} json
 */
function translate(json, key, vars) {
  let text = get(json, api.check.nestedProps(key));
  // Replace any passed in variables in the translation string.
  Object.keys(vars).map((/** @type string */k) => {
    const regex = new RegExp(`{{${k}}}`, "g");
    text = text.replace(regex, vars[k]);
  });

  return text || key;
}

/**
 * @param {string} key
 * @param {{ [x: string]: any; }} [vars]
 */
function translate_now(key, vars) {
  let text = get(json, api.check.nestedProps(key));

  // Replace any passed in variables in the translation string.
  if (vars) {
    Object.keys(vars).map((/** @type string */k) => {
      const regex = new RegExp(`{{${k}}}`, "g");
      text = text.replace(regex, vars[k]);
    });
  }

  return text || key;
}

/**
 * @param {string} lang
 */
function changeLangFn(lang) {
  langDefault = lang;
  locale.update(() => langDefault);
  storageService.data.save('lang', langDefault)
  axios.post('/api/bpm/saveLang', { lang })
    .then(() => {
      getTranslation()
    })
    .catch(err => {
      console.error('... err from changelang', err);
    })
}


