<script>
  import { onDestroy, onMount } from "svelte";
  import { currentLanguage, t } from "../../../services/i18n.service";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import { taskExeBusinessObject, taskExeErrorStore } from "../task-exe-store";
  import taskExeApi from "../task-exe.api";
  import { isLoading } from "../../../stores";
  import apiService from "../../../services/api.service";
  import { get } from "svelte/store";
  import taskExeErrorService from "../task-exe-error.service";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let tplparents = [];
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  let hasError = false;
  let showHelp = false;
  let value;
  let myVisibility;
  let source = [];
  let stopUpdate = false;
  const pathToStore = tplparents.join("_") || "tpl-main";

  $: checkVisibility(visibilityFromParent, myVisibility);

  const taskExeBusinessObjectUnsubscribe = taskExeBusinessObject.subscribe(
    (bo) => {
      if (!bo) return;
      if (stopUpdate) return;
      value = apiService.getNestedFromPath(bo[pathToStore], props.varName);

      // INFO: check if we can check the source for new value in order to see if we can update it
      if (!props.arrMultiple) {
        source =
          apiService.getNestedFromPath(bo[pathToStore], props.dataSource) || [];

        // if autoComplete1Value property is true then set value to select:
        setTimeout(() => {
          if (props.autoComplete1Value) {
            if (source.length === 1) {
              value = source[0]?.value || source[0];
              stopUpdate = true;
              //// FIXME: this is the only idea I had to fix infinit cicle
              setTimeout(() => {
                valueChanged();
                setTimeout(() => {
                  stopUpdate = false;
                }, 10);
              });
            }
          }
        }, 0);
      }
    },
  );

  const taskExeErrorStoreUnsubscribe = taskExeErrorStore.subscribe(() => {
    const resultErrVars = taskExeErrorService.getErrorVars(pathToStore, props);
    if (resultErrVars?.boPath === pathToStore) {
      hasError = resultErrVars.hasError;
      showHelp = resultErrVars.showHelp;
    }
  });

  onMount(() => {
    if (props.arrMultiple) {
      if (props.arrMultiple_i18n) {
        source = props.arrMultiple_i18n[$currentLanguage].split(",") || [];
      } else {
        source = props.arrMultiple.split(",") || [];
      }
      if (props.autoComplete1Value) {
        if (source.length === 1) {
          value = source[0];
        }
      }
    } else {
      source =
        apiService.getNestedFromPath(
          get(taskExeBusinessObject)[pathToStore],
          props.dataSource,
        ) || [];
    }
    valueChanged(true);
  });

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
    taskExeErrorStoreUnsubscribe();
  });

  /**
   * @param {boolean} [init]
   */
  function valueChanged(init) {
    const isInit = init === true;
    taskExeBusinessObject.ensureValue(
      pathToStore,
      props.varName,
      value,
      isInit,
    );

    if (isInit) return;

    // INFO: Keep this here to not show invalid state on first rendering
    taskExeErrorService.checkValidation(props, pathToStore);

    taskExeApi.submit({
      id: item.id,
      boPath: pathToStore,
      tplId: templateProps?.tplId,
    });
  }

  /**
   * @param {string} parentVisibility
   * @param {string} elVisibility
   */
  function checkVisibility(parentVisibility, elVisibility) {
    myVisibility = apiService.check.widgetVisibility(
      parentVisibility,
      elVisibility,
      props,
    );
  }
</script>

<!-- {JSON.stringify($dynamicBoStore)} / {value} -->
{#if myVisibility !== "hidden"}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div id={item.id} class="mb-3">
    <!-- svelte-ignore a11y-label-has-associated-control -->
    <label class="form-label d-block">
      <TaskExeTplI18nLabel bind:props />{props.required ? "*" : ""}
    </label>
    <select
      id="select_{item.id}"
      class="form-select {props.class || ''}"
      class:is-invalid={hasError}
      style={props.style}
      aria-labelledby={props.label_i18n
        ? props.label_i18n[$currentLanguage]
        : props.label}
      bind:value
      on:change={() => {
        valueChanged();
      }}
      disabled={myVisibility === "disabled" || $isLoading}
    >
      <option value={undefined}
        >{props.placeholder_i18n
          ? props.placeholder_i18n[$currentLanguage]
          : props.placeholder || $t("generic.select")}</option
      >
      {#each source as opt}
        <option value={opt.value ? opt.value : opt}>
          {opt.name ? opt.name : opt}
        </option>
      {/each}
    </select>

    {#if showHelp}
      <div id={props.id + "-HelpBlock"} class="invalid-feedback">
        {props?.validationMsg_i18n?.[$currentLanguage]
          ? props.validationMsg_i18n[$currentLanguage]
          : props.validationMsg}
      </div>
    {/if}
  </div>
{/if}
