<script>
  import {
    Alarm,
    AppIndicator,
    BodyText,
    BoxArrowRight,
    BoxArrowUpRight,
    Collection,
    Folder,
    GraphUp,
    Grid3x3GapFill,
    House,
    Intersect,
    ListTask,
    Magic,
    People,
    Person,
    ShieldLock,
  } from "svelte-bootstrap-icons";
  import { push } from "svelte-spa-router";
  import authService from "../../services/auth.service";
  import {
    t,
    langs,
    locale,
    changeLang,
    currentLanguage,
  } from "../../services/i18n.service";
  import {
    userStore,
    layoutFluid,
    gridSizeStore,
    platformInfoStore,
    themeStore,
  } from "../../stores";
  import UtilLoader from "../utils/util-loader.svelte";
  import LayoutFluid from "./layout-fluid.svelte";
  import Theme from "../utils/theme.svelte";
  import {
    taskExeCommandStore,
    taskExeInfo,
    taskExeStore,
    taskExeTitle,
  } from "../task/task-exe-store";
  import taskExeApi from "../task/task-exe.api";
  import { location } from "svelte-spa-router";
  import TaskReassign from "../task/task-reassing/task-reassign.svelte";
  import TaskReassignModal from "../task/task-reassing/task-reassign-modal.svelte";
  import { tooltip } from "../../services/tooltip.service";
  import SitesMenu from "../sites/sites-menu.svelte";
  import { inboxStateStore } from "../inbox/inbox-store";
  import { get } from "svelte/store";
  import UtilModalChat from "../utils/util-modal-chat.svelte";

  let modalChatComponent;
</script>

<nav class="navbar fixed-top navbar-expand-md d-flex">
  <div class={$layoutFluid || "container-fluid"}>
    {#if !$taskExeTitle}
      {#if $platformInfoStore?._n}
        {#if $platformInfoStore?._n?.n === "kpmg"}
          {#if $themeStore === "light"}
            <a class="navbar-brand" href="#/">
              <img
                src="portal/imgs/logo_kpmg_dp.png"
                alt="Brand"
                width="auto"
                height="40"
              />
            </a>
          {:else}
            <a class="navbar-brand" href="#/">
              <img
                src="portal/imgs/logo_kpmg_dp_white.png"
                alt="Brand"
                width="auto"
                height="40"
              />
            </a>
          {/if}
        {:else if $platformInfoStore?._n?.n === "ngiq"}
          <a class="navbar-brand" href="#/">
            {#if $themeStore === "light"}
              <img
                src="portal/imgs/logo_engineiq_light.png"
                alt="Brand"
                width="auto"
                height="40"
              />
            {:else}
              <img
                src="portal/imgs/logo_engineiq_dark.png"
                alt="Brand"
                width="auto"
                height="40"
              />
            {/if}
          </a>
        {:else}
          <a class="navbar-brand text-primary fw-bold" href="#/">
            {$platformInfoStore._n.f}
          </a>
        {/if}
      {:else}
        <a class="navbar-brand" href="#/">
          <img
            src="portal/imgs/logo_tasky_cloud.png"
            alt="Brand"
            width="auto"
            height="40"
          />
        </a>
      {/if}
    {:else}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-missing-attribute -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <a
        class="selectable-item navbar-brand p-0 m-0 fs-3 flex-fill overflow-hidden"
        class:w-75={$taskExeStore && $gridSizeStore !== "xs"}
        on:click={() => {
          if ($taskExeInfo.standAlone !== "true") taskExeApi.saveTaskContext();
          setTimeout(() => {
            const inboxState = get(inboxStateStore);
            if (inboxState.state === "normal") {
              push("/");
            } else {
              taskExeCommandStore.command({ action: "close" });
            }
          }, 200);
        }}
      >
        {#if $platformInfoStore?._n?.n === "ngiq"}
          <img
            src="portal/imgs/logo_engineiq_small.png"
            alt="Brand"
            width="auto"
            height="30"
          />
        {/if}
        {$taskExeTitle?.i18n?.[$currentLanguage] || $taskExeTitle?.default}
      </a>
    {/if}

    {#if $taskExeInfo?.task}
      <div class="ms-auto me-3">
        <TaskReassign bind:item={$taskExeInfo.task} isNavbar={true} />
      </div>
    {/if}

    <!-- <UtilLoader/> -->
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon" />
    </button>
    <div
      class="collapse navbar-collapse justify-content-end"
      id="navbarNavDropdown"
    >
      <ul class="navbar-nav">
        <!-- Menu -->
        <li class="nav-item dropdown">
          <!-- svelte-ignore a11y-missing-attribute -->
          <a
            class="nav-link dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <Grid3x3GapFill width="20" height="20" />
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <!-- svelte-ignore a11y-missing-attribute -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
            <!-- <li> -->
            <!--   <a href="/portal2" target="_blank" class="dropdown-item"> -->
            <!--     <BoxArrowUpRight width="20" height="20" /> -->
            <!--     {$t("navBar.oldPortal")} -->
            <!--   </a> -->
            <!-- </li> -->
            <!-- svelte-ignore a11y-missing-attribute -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
            <SitesMenu></SitesMenu>
            <li>
              <div class="d-flex ms-2 mt-1">
                <div>
                  <Theme />
                </div>
                <div class="ms-3">
                  <LayoutFluid />
                </div>
                {#if $platformInfoStore?.ai?.docs}
                  <div class="ms-3">
                    <button
                      class="btn btn-outline-primary"
                      on:click={() => {
                        modalChatComponent.init({ what: "ai-docs" });
                      }}
                    >
                      <Magic></Magic>
                    </button>
                  </div>
                {/if}
              </div>
            </li>
          </ul>
        </li>

        <!-- Access to Admin -->
        <li class="nav-item dropdown">
          <!-- svelte-ignore a11y-missing-attribute -->
          <a
            class="nav-link dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            use:tooltip={$t("sidebar.adminConsole")}
          >
            <Collection width="20" height="20" />
            <!-- {$t("sidebar.adminConsole")} -->
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <!-- svelte-ignore a11y-missing-attribute -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
            <li
              class:selected-item={["/", "/home"].includes($location)}
              on:click={() => {
                push("/");
              }}
            >
              <a class="dropdown-item">
                <House width="20" height="20" />
                {$t("generic.home")}
              </a>
            </li>
            <!-- svelte-ignore a11y-missing-attribute -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
            <li
              class:selected-item={["/instancecheck"].includes($location)}
              on:click={() => {
                push("/instancecheck");
              }}
            >
              <a class="dropdown-item">
                <ListTask width="20" height="20" />
                {$t("home.admin.instanceCheck.title")}
              </a>
            </li>
            <!-- svelte-ignore a11y-missing-attribute -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
            <li
              class:selected-item={["/roles"].includes($location)}
              on:click={() => {
                push("/roles");
              }}
            >
              <a class="dropdown-item">
                <People width="20" height="20" />
                {$t("home.admin.roles.title")}
              </a>
            </li>
            <!-- svelte-ignore a11y-missing-attribute -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
            <li
              class:selected-item={["/dashboards"].includes($location)}
              on:click={() => {
                push("/dashboards");
              }}
            >
              <a class="dropdown-item">
                <GraphUp width="20" height="20" />
                {$t("home.admin.dashboards.title")}
              </a>
            </li>
            <!-- svelte-ignore a11y-missing-attribute -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
            <li
              class:selected-item={["/schedules"].includes($location)}
              on:click={() => {
                push("/schedules");
              }}
            >
              <a class="dropdown-item">
                <Alarm width="20" height="20" />
                {$t("home.admin.schedules.title")}
              </a>
            </li>
            <!-- svelte-ignore a11y-missing-attribute -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
            <li
              class:selected-item={["/connection"].includes($location)}
              on:click={() => {
                push("/connection");
              }}
            >
              <a class="dropdown-item">
                <Intersect width="20" height="20" />
                {$t("home.admin.conn.title")}
              </a>
            </li>
            <!-- svelte-ignore a11y-missing-attribute -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
            <li
              class:selected-item={["/dms"].includes($location)}
              on:click={() => {
                push("/dms");
              }}
            >
              <a class="dropdown-item">
                <Folder width="20" height="20" />
                {$t("home.admin.docRepo.title")}
              </a>
            </li>
            <!-- svelte-ignore a11y-missing-attribute -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
            <li
              class:selected-item={["/gdpr"].includes($location)}
              on:click={() => {
                push("/gdpr");
              }}
            >
              <a class="dropdown-item ms-0">
                <ShieldLock width="20" height="20" />
                {$t("home.admin.gdpr.title")}
              </a>
            </li>
            <!-- svelte-ignore a11y-missing-attribute -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
            <li
              class:selected-item={["/log"].includes($location)}
              on:click={() => {
                push("/log");
              }}
            >
              <a class="dropdown-item">
                <BodyText width="20" height="20" />
                {$t("home.admin.logs.title")}
              </a>
            </li>
            <!-- svelte-ignore a11y-missing-attribute -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
            <li
              class:selected-item={["/apps"].includes($location)}
              on:click={() => {
                push("/apps");
              }}
            >
              <a class="dropdown-item">
                <AppIndicator width="20" height="20" />
                {$t("home.admin.exposed.title")}
              </a>
            </li>
          </ul>
        </li>

        <!-- User: -->
        <li class="nav-item dropdown">
          <!-- svelte-ignore a11y-missing-attribute -->
          <a
            class="nav-link dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <Person width="20" height="20" />
            {$userStore?.firstName}
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <!-- svelte-ignore a11y-missing-attribute -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
            <li
              on:click={() => {
                push("/profile");
              }}
            >
              <a class="dropdown-item">
                <Person width="20" height="20" />
                {$t("navBar.profile")}
              </a>
            </li>
            <!-- svelte-ignore a11y-missing-attribute -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
            <li on:click={() => authService.logout()}>
              <a class="dropdown-item">
                <BoxArrowRight width="20" height="20" />
                {$t("navBar.logout")}
              </a>
            </li>
          </ul>
        </li>

        <!-- Langs: -->
        <li class="nav-item dropdown">
          <!-- svelte-ignore a11y-missing-attribute -->
          <a
            class="nav-link dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img src="portal/i18n/imgs/{langs[$locale].icon}" />
          </a>
          <ul
            class="dropdown-menu dropdown-menu-end"
            style="max-height: 50vh; overflow-x: scroll;"
          >
            {#each Object.entries(langs) as [key, value]}
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
              <li
                class={$locale === key ? "selected-item" : ""}
                on:click={() => {
                  changeLang(key);
                }}
              >
                <!-- svelte-ignore a11y-missing-attribute -->
                <a class="dropdown-item">
                  <img src="portal/i18n/imgs/{value.icon}" />
                  {$t("langs." + value.lang)}
                </a>
              </li>
            {/each}
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
<UtilLoader />

<TaskReassignModal />

<UtilModalChat bind:this={modalChatComponent}></UtilModalChat>

<style>
  li {
    cursor: pointer;
  }
</style>
