<script>
  import { onDestroy, onMount } from "svelte";
  import { taskExeBusinessObject, taskExeStore } from "../task-exe-store";
  import { currentLanguage } from "../../../services/i18n.service";
  import jq from "jquery";
  import _ from "underscore";
  import systemService from "./../../../services/system.service";
  import { isLoading } from "../../../stores";
  import dayjs from "dayjs";
  import { get } from "svelte/store";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string}[]} */
  export let parent = undefined;
  export let tplparents = [];
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;
  export let visibilityFromParent = "editable";

  let updateFromStore = true;
  let boundEventListenerLocalChangedInJS;
  let local = {};
  let i18n_currentLanguage = "en";
  let selfElement;

  const pathToStore = tplparents.join("_") || "tpl-main";
  const elementId = pathToStore ? pathToStore + "_" + item.id : item.id;

  const system = systemService.getSystem();

  const currentLanguageUnsubscribe = currentLanguage.subscribe((res) => {
    i18n_currentLanguage = res;
    const localChangedEvent = new CustomEvent(`i18nChanged_${elementId}`, {
      detail: res,
    });
    window.dispatchEvent(localChangedEvent);
  });

  const taskExeBusinessObjectUnsubscribe = taskExeBusinessObject.subscribe(
    (boInit) => {
      if (!boInit) return;
      const bo = boInit[pathToStore];
      if (!bo) return;
      if (props.useAngularJS) {
        if (!updateFromStore) return;
        Object.keys(bo).forEach((key) => {
          local[key] = bo[key];
        });
        const localChangedEvent = new CustomEvent(`localChanged_${elementId}`, {
          detail: local,
        });
        window.dispatchEvent(localChangedEvent);
      } else {
        Object.keys(bo).forEach((key) => {
          local[key] = bo[key];
        });
        const localChangedEvent = new CustomEvent(`localChanged_${elementId}`, {
          detail: local,
        });
        window.dispatchEvent(localChangedEvent);
      }
    },
  );

  onDestroy(() => {
    // window.removeEventListener(`localChanged_${item.id}`);
    taskExeBusinessObjectUnsubscribe();
    currentLanguageUnsubscribe();
    window.removeEventListener(
      `localChanged_${elementId}`,
      boundEventListenerLocalChangedInJS,
    );
    window.removeEventListener(
      `i18nChanged_${elementId}`,
      boundEventListenerLocalChangedInJS,
    );
  });

  onMount(() => {
    let context = {
      widgetElement: jq(selfElement),
      jQuery: jq,
      local,
      system,
      _,
      dayjs,
      subscribeLocal: (bo) => {},
      isLoading: () => {
        return (function () {
          return get(isLoading);
        })();
      },
      // updateBo // is a function check below
    };

    const fn1 = `window.addEventListener(
        'localChanged_${elementId}',
        (/** @type {{detail: any} */ event) => {
          this.local = event.detail;
          if (this.subscribeLocal) 
            this.subscribeLocal(this.local);
        }
      );`;
    const fn2 = `this.updateBo = updateBo; updateBo = undefined;`;
    const fn3 = `window.addEventListener(
        'i18nChanged_${elementId}',
        (/** @type {{detail: any} */ event) => {
          this.i18n = {locale: event.detail};
          if (this.subscribeI18n) 
            this.subscribeI18n(this.i18n);
        }
      );`;

    try {
      boundEventListenerLocalChangedInJS = new Function(
        "updateBo",
        [fn1, fn2, props.script].join("\r\n"),
      ).bind(context)(updateBo);
      // new Function("local", props.script).bind()(local);
    } catch (err) {
      console.error(
        "There was an error while compiling HTML Widget: " + String(err),
      );
    }
  });

  function updateBo() {
    taskExeBusinessObject.update((boInit) => {
      const bo = boInit[pathToStore];
      Object.keys(local).forEach((key) => {
        bo[key] = local[key];
      });
      return boInit;
    });
  }
</script>

<!-- {JSON.stringify(templateProps)} -->
<!-- {parentsTplPath} {JSON.stringify($dynamicBoStore)} -->
<!-- {elementId} / {parentsTplPath} -->
<div id={elementId} bind:this={selfElement} class="mb-3">
  {@html props.html || ""}
</div>
