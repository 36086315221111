<script>
  import { onDestroy, onMount } from "svelte";
  import { nanoid } from "nanoid";
  import Quill from "quill";
  import TaskExeTplI18nLabel from "./task-exe-tpl-i18n-label.svelte";
  import { taskExeBusinessObject, taskExeErrorStore } from "../task-exe-store";
  import apiService from "../../../services/api.service";
  import { isLoading } from "../../../stores";
  import { currentLanguage } from "../../../services/i18n.service";
  import jq from "jquery";
  import { get } from "svelte/store";
  import taskExeErrorService from "../task-exe-error.service";

  /** @type {{id: string;}|undefined} */
  export let item = undefined;
  export let props = {};
  /** @type {{id: string; type: string;}[]} */
  export let parent = undefined;
  export let tplparents = [];
  export let visibilityFromParent = "editable";
  /** @type {{id: string; tplId: string;}|null} */
  export let templateProps = null;

  const DEFAULT_VALUE = "<p><br></p>";
  let quillContainer;
  let quill;
  let uuid;
  let hasError = false;
  let showHelp = false;
  let myVisibility;
  const pathToStore = tplparents.join("_") || "tpl-main";
  const widgetId = pathToStore ? pathToStore + "_" + item.id : item.id;

  const delaySave = apiService.debounce(valueChanged, 500);

  $: checkVisibility(visibilityFromParent, myVisibility);

  const isLoadingUnsubscribe = isLoading.subscribe((res) => {
    if (quill) {
      if (res) quill?.enable(false);
      else quill?.enable(true);
    }
  });

  const taskExeBusinessObjectUnsubscribe = taskExeBusinessObject.subscribe(
    (bo) => {
      if (!bo) return;
      // INFO: We don't update the value because it is not needed!
      // The scenario is that WYSIWYG is used only for writing and
      // doesn't need to reupdate the widget
      // If we try to reupdate the widget we have to retain also
      // the cursor location!!!

      if (props.visibilityVar) {
        myVisibility = apiService.getNestedFromPath(
          bo[pathToStore],
          props.visibilityVar,
        );
      }
    },
  );

  const taskExeErrorStoreUnsubscribe = taskExeErrorStore.subscribe(() => {
    const resultErrVars = taskExeErrorService.getErrorVars(pathToStore, props);
    if (resultErrVars?.boPath === pathToStore) {
      hasError = resultErrVars.hasError;
      showHelp = resultErrVars.showHelp;
    }
  });

  onMount(() => {
    uuid = nanoid(10);
    valueChanged(true);
    const firstValue =
      apiService.getNestedFromPath(
        get(taskExeBusinessObject)[pathToStore],
        props.varName,
      ) || DEFAULT_VALUE;

    setTimeout(() => {
      try {
        quill = new Quill("#quill-" + uuid, {
          theme: "snow",
          //debug: "info",
          modules: {
            toolbar: true,
          },
        });
        quill.root.innerHTML = firstValue;
        quill.on("text-change", (_delta, _oldDelta, source) => {
          if (source == "api") {
          } else if (source == "user") {
            delaySave();
          }
        });
        toggleEnableQuill(myVisibility);
      } catch (e) {
        console.error(e);
      }
    }, 0);
  });

  onDestroy(() => {
    taskExeBusinessObjectUnsubscribe();
    isLoadingUnsubscribe();
    taskExeErrorStoreUnsubscribe();
    quill = void 0;
  });

  /**
   * @param {boolean} [init]
   */
  function valueChanged(init) {
    const isInit = init === true;
    //const quilval = quill.getText();
    if (!quill) return;
    const quillval = quill.root.innerHTML;

    if (isInit) return;

    // INFO: Keep this here to not show invalid state on first rendering
    taskExeErrorService.checkValidation(props, pathToStore);

    setTimeout(() => {
      taskExeBusinessObject.ensureValue(
        pathToStore,
        props.varName,
        quillval,
        isInit,
      );
      //taskExeApi.submit({
      //  id: item.id,
      //  boPath: pathToStore,
      //  tplId: templateProps?.tplId,
      //});
    });
  }

  /**
   * @param {string} parentVisibility
   * @param {string} elVisibility
   */
  function checkVisibility(parentVisibility, elVisibility) {
    myVisibility = apiService.check.widgetVisibility(
      parentVisibility,
      elVisibility,
      props,
    );
    setTimeout(() => {
      toggleEnableQuill(myVisibility);
    }, 100);
  }

  /**
   * @param {string} visibility
   */
  function toggleEnableQuill(visibility) {
    if (!quill) return;
    const disabled = visibility === "disabled";
    quill?.enable(!disabled);
    // jq(quillContainer)
    //   .parent()
    //   .find(".ql-toolbar")
    //   .find("input,button,select")
    //   .prop("disabled", disabled);
    jq(quillContainer)
      .parent()
      .find(".ql-toolbar,.ql-container")
      .css(
        "background-color",
        disabled ? "var(--bs-form-control-disabled-bg)" : "inherit",
      );
  }
</script>

{#if myVisibility !== "hidden"}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div id={item.id} class="mb-3 {props.class || ''}">
    <label class="form-label d-block" for="rt_{widgetId}">
      <TaskExeTplI18nLabel bind:props />{props.required ? "*" : ""}
    </label>
    <div id="quill-{uuid}" bind:this={quillContainer} />

    {#if showHelp}
      <div id={props.id + "-HelpBlock"} class="invalid-feedback d-block">
        {props?.validationMsg_i18n?.[$currentLanguage]
          ? props.validationMsg_i18n[$currentLanguage]
          : props.validationMsg}
      </div>
    {/if}
  </div>
{/if}
