<script>
  import async from "async";
  import { createEventDispatcher } from "svelte";
  import { t, tNow } from "../../services/i18n.service";
  import FieldCheckbox from "../utils/field-checkbox.svelte";
  import FieldInput from "../utils/field-input.svelte";
  import FieldTextarea from "../utils/field-textarea.svelte";
  import UtilModal from "../utils/util-modal.svelte";
  import connectionService from "./connection.service";
  import toasterService from "../../services/toaster.service";
  import FieldSwitch from "../utils/field-switch.svelte";
  const dispatch = createEventDispatcher();

  let modalShow = false;
  let currentItem = {};
  let currentItemUsed = [];
  let modalValidation = {};
  let integrationDefs = [];

  /**
   * @param {string} kind
   */
  export function openNewItem(kind) {
    const setts = getDefaultSetts(kind);
    currentItem = { kind, settings: setts };
    modalShow = true;
  }

  /**
   * @param {string} itemId
   * @param {any} pc_id
   */
  export function openExistingItem(itemId, pc_id) {
    getInfo({ _id: itemId, pc_id });
  }

  /**
   * @param {string} kind
   */
  function getDefaultSetts(kind) {
    const selectedIntDef = integrationDefs.find((el) => el.value === kind);
    const setts = selectedIntDef?.definition?.connection?.reduce(
      (/** @type {any} */ o, /** @type {any} */ el) => {
        return { ...o, [el]: null };
      },
      {},
    );
    return setts;
  }

  /**
   * @param {any} item
   */
  function getInfo(item) {
    if (!item?._id) {
      return;
    }
    const itemId = item._id;
    // @ts-ignore
    async.parallel(
      [
        // get where used:
        (/** @type {Function} */ callback) => {
          connectionService
            .getWhereUsed(item.pc_id)
            .then((res) => {
              currentItemUsed = res.data;
              callback();
            })
            .catch((err) => {
              callback(err);
            });
        },
        // get current details of connection:
        (/** @type {Function} */ callback) => {
          connectionService
            .getOne(itemId)
            .then((res) => {
              currentItem = res.data;
              if (!currentItem.settings) {
                currentItem.settings = getDefaultSetts(currentItem.kind);
              }
              callback();
            })
            .catch((err) => {
              callback(err);
            });
        },
      ],
      (/** @type {any} */ err) => {
        if (err) {
          console.error(err);
          return;
        }
        modalShow = true;
      },
    );
  }

  /**
   * @param {string} action
   */
  function modalClose(action) {
    if ("close" === action) {
      currentItemUsed = [];
      currentItem = undefined;
      modalValidation = {};
      modalShow = false;
    }
    if ("save" === action) {
      modalValidation = connectionService.validate(currentItem);
      if (!modalValidation?.isOk) return;
      connectionService
        .save(currentItem)
        .then((res) => {
          modalShow = false;
          toasterService.success({ msg: tNow("generic.saved") });
          // search();
          dispatch("callback", { _id: res.data._id });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
</script>

<UtilModal
  title={$t("diaIntegrCenter.title")}
  open={modalShow}
  autoClose={false}
  onClosed={modalClose}
  modalButtons={[
    { label: $t("generic.close"), action: "close", class: "btn-secondary" },
    {
      label: $t("generic.save"),
      action: "save",
      class: "btn-outline-primary",
    },
  ]}
  size="lg"
>
  <div class="row">
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <h5>{$t("generic.info")}</h5>
          <FieldInput
            label={$t("diaIntegrCenter.pConnName")}
            bind:value={currentItem.name}
            invalid={modalValidation?.info?.name}
            disabled={true}
          />
          <div class="mb-3">
            <span class="text-muted">
              {$t("diaIntegrCenter.pType")}:
              <span class="text-uppercase text-primary">
                {currentItem.kind}
              </span>
            </span>
          </div>
          <FieldTextarea
            label={$t("generic.desc")}
            bind:value={currentItem.desc}
            disabled={true}
          />
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <h5>{$t("generic.details")}</h5>
          {#if currentItem.settings}
            {#each Object.entries(currentItem?.settings) as [key, _value]}
              {#if ["json", "secure", "waitForResponse"].includes(key)}
                <FieldCheckbox
                  label={$t("generic." + key)}
                  bind:value={currentItem.settings[key]}
                  disabled={false || currentItem.org_db_mongo}
                />
              {:else if ["password", "pass", "tkn"].includes(key)}
                <FieldInput
                  label={$t("generic." + key)}
                  bind:value={currentItem.settings[key]}
                  type="password"
                  autocomplete="new-password"
                  disabled={false || currentItem.org_db_mongo}
                />
              {:else}
                <FieldInput
                  label={$t("generic." + key)}
                  bind:value={currentItem.settings[key]}
                  disabled={false || currentItem.org_db_mongo}
                />
              {/if}
            {/each}
          {/if}
          <FieldSwitch
            label="Set my own MongoDB database"
            bind:value={currentItem.org_db_mongo}
          />
        </div>
      </div>
    </div>
    {#if currentItem._id}
      <div class="col-12">
        <div class="mt-3">
          {$t("generic.usedIn")}:
        </div>
        <ul class="tsk-used-in ps-3 used-in-height">
          {#if currentItemUsed.length === 0}
            <li class="text-muted">{$t("generic.notUsed")}</li>
          {/if}
          {#each currentItemUsed as item}
            <li>
              <span class="text-muted">{$t("generic.process")}:</span>
              {item.process},
              <span class="text-muted">{$t("generic.activity")}:</span>
              {item.name} ({item.type})
              <!-- <button -->
              <!--   class="btn btn-sm btn-link p-0" -->
              <!--   on:click={() => openService(item)} -->
              <!-- > -->
              <!--   <BoxArrowUpRight class="tsk-btn-a" width="16" height="16" /> -->
              <!-- </button> -->
            </li>
          {/each}
        </ul>
      </div>
    {/if}
  </div>
</UtilModal>

<style>
  :global(.tsk-btn-a) {
    top: -4px;
    position: relative;
  }

  .tsk-used-in > li {
    list-style: none;
  }

  .used-in-height {
    max-height: 210px;
    overflow-y: scroll;
  }
</style>
