<script>
  import {
    Buildings,
    CalendarCheck,
    CalendarEvent,
    CalendarPlus,
    People,
    Person,
    Robot,
    Search,
    X,
  } from "svelte-bootstrap-icons";
  import { push } from "svelte-spa-router";
  import { currentLanguage, t } from "../../services/i18n.service";
  import { onDestroy, onMount } from "svelte";
  import {
    inboxCommandStore,
    inboxTaskListStore,
    inboxTaskListCountStore,
    inboxTaskTagsStore,
    inboxStateStore,
  } from "./inbox-store";
  import TaskReassign from "../task/task-reassing/task-reassign.svelte";
  import FieldDateFormated from "../utils/field-date-formated.svelte";
  import { tooltip } from "../../services/tooltip.service";
  import jq from "jquery";
  import UtilPagination from "../utils/util-pagination.svelte";
  import dayjs from "dayjs";
  import { get } from "svelte/store";
  import { taskExeCommandStore } from "../task/task-exe-store";
  import { aiTasks } from "../ai/ai.store";
  import { SyncLoader } from "svelte-loading-spinners";
  import { platformInfoStore } from "../../stores";

  // let showTask = false;
  export let listType = "table"; // table | list
  let taskParams = {};
  let items = [];
  let itemsNo = 0;
  let query = {
    sort: "-created_at",
    limit: 10,
    skip: 0,
    q: null,
    status: null,
  };
  let isLoading = false;
  let toastAiTaskNumber = 0;

  const taskExeCommandStoreUnsubscribe = taskExeCommandStore.subscribe(
    (res) => {
      if (res?.action === "open") {
        if (!taskParams) taskParams = {};
        taskParams.tid = res?.details?.details?.tid;
        taskParams.dashboard = res?.details?.details?.dashboard;
      } else if (res?.action === "close") {
        taskParams = {};
        search();
      }
    },
  );

  const inboxCommandStoreUnsubscribe = inboxCommandStore.subscribe(
    (command) => {
      if (command === "refresh") {
        search();
      }
    },
  );

  const inboxTaskListStoreUnsubscribe = inboxTaskListStore.subscribe((res) => {
    res.forEach(checkDueDate);
    items = res;
  });

  const inboxTaskListCountStoreUnsubscribe = inboxTaskListCountStore.subscribe(
    (res) => {
      itemsNo = res;
      if ($platformInfoStore?.ai?.tasks) {
        if (toastAiTaskNumber != itemsNo) {
          aiTasks.refresh(query);
          toastAiTaskNumber = itemsNo;
        }
      }
    },
  );

  const inboxTaskTagsStoreUnsubscribe = inboxTaskTagsStore.subscribe((tags) => {
    const statusActive = tags.includes("active");
    const statusClosed = tags.includes("closed");
    const onlyMyTasks = tags.includes("mytasks");
    const onlyRoleTasks = tags.includes("roletasks");
    const withDueDate = tags.includes("duedate");
    const withOverdue = tags.includes("overdue");

    if (statusActive) query.status = "active";
    else if (statusClosed) query.status = "closed";
    else query.status = "active";

    if (onlyMyTasks) query.only = "mytasks";
    else if (onlyRoleTasks) query.only = "roletasks";
    else delete query.only;

    if (withDueDate) query.opt = true;
    else delete query.opt;

    if (withOverdue) query.overdue = true;
    else delete query.overdue;

    search();
  });

  onDestroy(() => {
    inboxCommandStoreUnsubscribe();
    inboxTaskListStoreUnsubscribe();
    inboxTaskListCountStoreUnsubscribe();
    inboxTaskTagsStoreUnsubscribe();
    taskExeCommandStoreUnsubscribe();
    // locationUnsubscribe();
  });

  onMount(() => {
    search();
  });

  function search() {
    getItems();
    getItemsNo();
    jq("#search-input").trigger("focus");
  }

  function resetQuery() {
    query.q = null;
    search();
  }

  function getItems() {
    isLoading = true;
    inboxTaskListStore.search(query, () => {
      isLoading = false;
    });
  }

  function getItemsNo() {
    inboxTaskListCountStore.count(query);
  }

  /**
   * @param {any} item
   */
  function getAssignedTo(item) {
    let result = "";
    if (item?.user) {
      result = item?._user?.firstName + " " + item?._user?.lastName;
    } else if (item?.team?.name) {
      result = item?.team?.name;
    } else {
      result = item?.role?.name;
      if (item?.role?.name_i18n?.[$currentLanguage]) {
        result = item?.role?.name_i18n[$currentLanguage];
      }
    }

    if (!result) result = "-";
    return result;
  }

  /**
   * @param {{ status: string; _id: any; }} item
   */
  function openTask(item) {
    if (item?.status !== "active") return;
    taskParams = {
      tid: item._id,
      dashboard: false,
    };
    // showTask = true;
    const inboxState = get(inboxStateStore).state;
    if (inboxState === "normal") {
      push(`/task/${item._id}`);
    } else {
      taskExeCommandStore.command({
        action: "open",
        details: { details: { tid: item._id, dashboard: false } },
      });
    }
  }

  /**
   * @param {{ dueDate: string | number | dayjs.Dayjs | Date; created_at: string | number | dayjs.Dayjs | Date; dueDateCalc: number; dueDateStatus: string; }} item
   */
  function checkDueDate(item) {
    if (!item.dueDate) return;
    const now = dayjs();
    const dueDate = dayjs(item.dueDate);
    const createdDate = dayjs(item.created_at);
    const diffNow = dueDate.diff(now);
    const diffAll = dueDate.diff(createdDate);
    const diffPercent = diffNow / diffAll;
    if (diffPercent <= 0) {
      item.dueDateStatus = "danger";
    } else if (diffPercent <= 0.3) {
      item.dueDateStatus = "warning";
    } else {
      item.dueDateStatus = "primary";
    }
  }
</script>

<!-- params: {JSON.stringify(params)}<br /> -->
<!-- taskparams: {JSON.stringify(taskParams)}<br /> -->
<!-- dashboard id: {dashboardId}<br /> -->
<!-- <select bind:value={$inboxStateStore.state}> -->
<!--   <option value="normal">normal</option> -->
<!--   <option value="sites">sites</option> -->
<!-- </select> -->
<!-- | {$inboxStateStore.state} -->

<!-- {#if $inboxStateStore.state === "sites" && taskParams?.tid} -->
<!-- <button -->
<!--   class="btn btn-secondary" -->
<!--   on:click={() => { -->
<!--     showTask = false; -->
<!--     taskParams = {}; -->
<!--   }} -->
<!-- > -->
<!--   back to tasks -->
<!-- </button> -->

<!--   <Task isComponent={true} myParams={taskParams}></Task> -->
<!-- {:else} -->
<div class="row">
  <div class="col-md-5">
    <div class="d-flex mb-3">
      <div class="input-group me-3">
        <input
          id="search-input"
          type="text"
          class="form-control"
          placeholder={$t("home.search.placeholder")}
          aria-label={$t("home.search.placeholder")}
          aria-describedby={$t("home.search.placeholder")}
          bind:value={query.q}
          on:keyup={(ev) => {
            if (ev.code === "Enter") {
              search();
            }
          }}
        />
        <button
          class="btn btn-primary"
          type="button"
          id="button-addon2"
          on:click={search}
        >
          <Search></Search>
        </button>
        <button
          class="btn btn-outline-secondary"
          type="button"
          id="button-addon2"
          on:click={resetQuery}
        >
          <X></X>
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-6 mb-3">
    <div class="d-flex">
      <div class="d-block d-md-none text-muted me-3 fs-3">Filters:</div>
      {#each $inboxTaskTagsStore as tag}
        <button
          class="btn btn-outline-secondary me-3 position-relative"
          on:click={() => {
            inboxTaskTagsStore.toggle(tag);
          }}
        >
          {#if !["active", "closed"].includes(tag)}
            <span
              class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
              >x</span
            >
          {/if}
          {#if tag === "active"}
            {$t("instCheck.active")}
          {:else if tag === "closed"}
            {$t("instCheck.closed")}
          {:else if tag === "mytasks"}
            {$t("instCheck.onlyMyTasks")}
          {:else if tag === "roletasks"}
            {$t("instCheck.onlyMyRolesTasks")}
          {:else if tag === "duedate"}
            {$t("instCheck.dueDate")}
          {:else if tag === "overdue"}
            {$t("instCheck.overdue")}
          {:else}
            -
          {/if}
        </button>
      {/each}
    </div>
  </div>
</div>

{#if $platformInfoStore?.ai?.tasks}
  <div class="d-flex">
    <div class="me-3">
      <Robot width={25} height={25}></Robot>
    </div>
    {#if !$aiTasks?.description}
      <div style="position: relative; top: -7px;">
        <SyncLoader color="#17575e" size="40" unit="px" duration="1s" />
        <!-- <div class="spinner-border text-secondary" role="status"> -->
        <!--   <span class="visually-hidden">Loading...</span> -->
        <!-- </div> -->
      </div>
    {:else}
      <div class="text-muted" style="font-size: 1.2rem;">
        {$aiTasks?.description || "-"}
      </div>
    {/if}
  </div>
{/if}

{#if listType === "list"}
  <ul class="list-group">
    {#each items as item}
      <li class="list-group-item d-flex">
        {#if item?.status === "active"}
          <div class="">
            <TaskReassign bind:item></TaskReassign>
          </div>
        {/if}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div
          class="selectable-item w-100"
          on:click={() => {
            openTask(item);
          }}
        >
          <div class="fs-5 text-underline">
            {#if item?.subject_i18n?.[$currentLanguage]}
              {item.subject_i18n[$currentLanguage]}
            {:else}
              {item?.subject || item?.name}
            {/if}
          </div>
          <div class="text-muted row" style="font-size: 0.8rem;">
            <div class="col-md-6">
              <div use:tooltip={$t("home.taskHeader.organisation")}>
                <Buildings></Buildings>
                {item?.orgId?.name || "-"}
              </div>
              <div use:tooltip={$t("home.taskHeader.assignedTo")}>
                {#if item.user}
                  <Person></Person>
                {:else}
                  <People></People>
                {/if}
                {getAssignedTo(item)}
              </div>
            </div>
            <div class="col-md-6">
              <div use:tooltip={$t("home.taskHeader.creationDate")}>
                <CalendarPlus></CalendarPlus>
                <FieldDateFormated date={item?.created_at}></FieldDateFormated>
              </div>
              {#if query.status === "closed"}
                <div use:tooltip={$t("home.taskHeader.closedDate")}>
                  <CalendarCheck></CalendarCheck>
                  <FieldDateFormated date={item?.closed_at}></FieldDateFormated>
                </div>
              {/if}
              <div
                class="selectable-item"
                class:text-danger={item?.dueDateStatus === "danger"}
                class:text-warning={item?.dueDateStatus === "warning"}
                class:text-primary={item?.dueDateStatus === "primary"}
                use:tooltip={$t("home.taskHeader.dueDate")}
              >
                <CalendarEvent></CalendarEvent>
                <FieldDateFormated date={item?.dueDate}></FieldDateFormated>
              </div>
            </div>
          </div>
        </div>
      </li>
    {:else}
      <li class="list-group-item text-muted">
        {$t("home.tasks.noTasksAvailable")}
      </li>
    {/each}
  </ul>
{:else}
  <table class="table table-hover">
    <tbody>
      {#each items as item}
        <tr>
          <td style="width: 30px;">
            {#if item?.status === "active"}
              <TaskReassign bind:item></TaskReassign>
            {/if}
          </td>
          <!-- <td style="width: 30px;"> -->
          <!--   <Star></Star> -->
          <!-- </td> -->
          <td
            class="selectable-item"
            on:click={() => {
              openTask(item);
            }}
          >
            <div class="fs-5 text-underline">
              {#if item?.subject_i18n?.[$currentLanguage]}
                {item.subject_i18n[$currentLanguage]}
              {:else}
                {item?.subject || item?.name}
              {/if}
            </div>
            <div
              class="text-muted d-md-inline-flex gap-3"
              style="font-size: 0.8rem;"
            >
              <div use:tooltip={$t("home.taskHeader.organisation")}>
                <Buildings></Buildings>
                {item?.orgId?.name || "-"}
              </div>
              <div use:tooltip={$t("home.taskHeader.assignedTo")}>
                {#if item.user}
                  <Person></Person>
                {:else}
                  <People></People>
                {/if}
                {getAssignedTo(item)}
              </div>
              <div use:tooltip={$t("home.taskHeader.creationDate")}>
                <CalendarPlus></CalendarPlus>
                <FieldDateFormated date={item?.created_at}></FieldDateFormated>
              </div>
              {#if query.status === "closed"}
                <div use:tooltip={$t("home.taskHeader.closedDate")}>
                  <CalendarCheck></CalendarCheck>
                  <FieldDateFormated date={item?.closed_at}></FieldDateFormated>
                </div>
              {/if}
              <div
                class="selectable-item"
                class:text-danger={item?.dueDateStatus === "danger"}
                class:text-warning={item?.dueDateStatus === "warning"}
                class:text-primary={item?.dueDateStatus === "primary"}
                use:tooltip={$t("home.taskHeader.dueDate")}
              >
                <CalendarEvent></CalendarEvent>
                <FieldDateFormated date={item?.dueDate}></FieldDateFormated>
              </div>
            </div>
          </td>
        </tr>
      {:else}
        <tr>
          <td colspan="2" class="text-muted">
            {$t("home.tasks.noTasksAvailable")}
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
{/if}

<UtilPagination bind:query bind:totalData={itemsNo} on:callData={getItems} />
<!-- {/if} -->
<!-- {#if items.length === 0} -->
<!--   <div class="mt-5 mb-3"> -->
<!--     <InboxRedesignTaskCharts></InboxRedesignTaskCharts> -->
<!-- <div class="text-center fs-1 text-muted mt-5 mb-5"> -->
<!--   Start being performant today! -->
<!-- </div> -->
<!--   </div> -->
<!--   <InboxRedesignTaskExposed></InboxRedesignTaskExposed> -->
<!-- {/if} -->
